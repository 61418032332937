import React from "react"

const LeftArrow = () => {
  return (
    <svg
      width='48'
      height='36'
      viewBox='0 0 48 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='48' height='36' rx='12' fill='white' fill-opacity='0.2' />
      <path
        opacity='0.4'
        d='M31.4998 18.8333H16.4998C16.039 18.8333 15.6665 18.46 15.6665 18C15.6665 17.54 16.039 17.1667 16.4998 17.1667H31.4998C31.9607 17.1667 32.3332 17.54 32.3332 18C32.3332 18.46 31.9607 18.8333 31.4998 18.8333Z'
        fill='white'
      />
      <path
        d='M22.3334 24.6667C22.1201 24.6667 21.9067 24.5851 21.7442 24.4226L15.9109 18.5892C15.585 18.2634 15.585 17.7367 15.9109 17.4108L21.7442 11.5775C22.07 11.2517 22.5968 11.2517 22.9226 11.5775C23.2484 11.9033 23.2484 12.4301 22.9226 12.7559L17.6785 18L22.9226 23.2442C23.2484 23.57 23.2484 24.0967 22.9226 24.4226C22.7601 24.5851 22.5467 24.6667 22.3334 24.6667Z'
        fill='white'
      />
    </svg>
  )
}

export default LeftArrow
