import React from "react"
import { useLottie } from "lottie-react"
import { useSelector } from "react-redux"
import { DotLottieReact } from "@lottiefiles/dotlottie-react"
import { Header } from "../core/headers"
import useWindowDimensions from "../../hooks/useWindowDimensionsWeb"
import cubicalLoader from "../../assets/images/v3/cube-loader.lottie"

const renderLottie = src => {
  return <DotLottieReact src={src} loop autoplay />
}
const CubeAppLoader = () => {
  const screen = useSelector(state => state.screen)
  const headerConfig = screen?.pwaHeader
  const height = useWindowDimensions().height

  return (
    <>
      {headerConfig?.primaryLogoUrl && <Header />}
      <div className='cube-loader-container' style={{ maxHeight: height }}>
        <div className='cube-loader'>{renderLottie(cubicalLoader)}</div>
        <div className='cube-loader-text'>
          <p className='cube-loader-text-1'>Just hold a little longer..</p>
          <p className='cube-loader-text-2'>You’ll be there in a moment..</p>
        </div>
      </div>
    </>
  )
}

export default CubeAppLoader
