import React, { createContext, useState, useContext, useEffect } from "react"
import { AuthenticationType, BottomSheetType } from "../../utils/enums"
import OtpBottomSheet_v3 from "./otp/Otp_bottom_sheet_v3"
import Mpin_v3 from "./mpin/Mpin_v3"
import CustomerAuthBottomSheet from "./customer/CustomerAuthBottomSheet"
import SuccessBottomSheet from "../BottomSheet/SuccessBottomSheet"
import { useNavigate } from "react-router-dom"
import FailureBottomSheet from "../BottomSheet/FailureBottomSheet"
import SetMpin_v3 from "./mpin/setMpin/setMpin_v3"
import ResetPin_v3 from "../MockSwitch/ResetPin/ResetPin_v3"
import { BottomSheet } from "react-spring-bottom-sheet"
import SetMpinBottomSheetV3 from "./mpin/SetMpinBottomSheetV3"
import { ErrorContext } from "./ErrorScreenContext"
import { resetErrorTryAgainCount } from "../../utils/functions"

const BottomSheetContext = createContext()

export const BottomSheetProvider = ({ children }) => {
  const navigate = useNavigate()
  const { clearErrorState } = useContext(ErrorContext)
  const [bottomSheet, setBottomSheet] = useState(null)
  const [bottomSheet2, setBottomSheet2] = useState(null)

  const openBottomSheet = (
    type,
    title = "",
    subtitle = "",
    navigateTo,
    time = 3000,
    onCtaClick,
    showRetryButton = true,
    showSecondaryCta = false,
    showSuccessSecondaryButton = false,
    showSuccessPrimaryButton = false,
    primarySuccessButtonText = null,
    secondarySuccessButtonText = null,
    additionalFields,
    onSuccessPrimaryCtaClick,
    onSuccessSecondaryClick,
    onSecondaryCtaClick,
    primaryCtaText,
  ) => {
    const navigateAfterSuccess = () => {
      setTimeout(() => {
        setBottomSheet(null)
        if (navigateTo) navigate(navigateTo)
      }, time)
    }
    if (type == AuthenticationType.OTP) {
      setBottomSheet(
        <OtpBottomSheet_v3
          isOpen={true}
          onClose={() => {
            setBottomSheet(null)
            setBottomSheet2(null)
            window.scrollTo(0, 0)
          }}
        />,
      )
    } else if (type == AuthenticationType.MPIN) {
      setBottomSheet(
        <Mpin_v3
          isOpen={true}
          onClose={() => {
            setBottomSheet(null)
            setBottomSheet2(null)
            window.scrollTo(0, 0)
          }}
        />,
      )
    } else if (type == AuthenticationType.CUSTOMER_VERIFY) {
      setBottomSheet(
        <CustomerAuthBottomSheet
          isOpen={true}
          onClose={() => {
            setBottomSheet(null)
            setBottomSheet2(null)
            window.scrollTo(0, 0)
          }}
        />,
      )
    } else if (type == BottomSheetType.SUCCESS) {
      setBottomSheet(
        <SuccessBottomSheet
          title={title}
          subtitle={subtitle}
          isOpen={true}
          onClose={() => setBottomSheet(null)}
          showSuccessSecondaryButton={showSuccessSecondaryButton}
          showSuccessPrimaryButton={showSuccessPrimaryButton}
          primarySuccessButtonText={primarySuccessButtonText}
          secondarySuccessButtonText={secondarySuccessButtonText}
          onSuccessPrimaryCtaClick={() => onSuccessPrimaryCtaClick()}
          onSuccessSecondaryClick={() => onSuccessSecondaryClick()}
        />,
      )
      if (!showSuccessPrimaryButton && !showSuccessSecondaryButton)
        navigateAfterSuccess()
    } else if (type == BottomSheetType.FAILURE) {
      setBottomSheet(
        <FailureBottomSheet
          title={title}
          subtitle={subtitle}
          isOpen={true}
          onClose={() => {
            clearErrorState()
            resetErrorTryAgainCount()
            setBottomSheet(null)
            window.scrollTo(0, 0) // for ios scroll issue
          }}
          onCtaClick={onCtaClick}
          onSecondaryCtaClick={() => {
            navigate("/")
            clearErrorState()
            resetErrorTryAgainCount()
            setBottomSheet(null)
            setBottomSheet2(null)
          }}
          showRetryButton={showRetryButton}
          showSecondaryCta={showSecondaryCta}
          primaryCtaText={primaryCtaText}
        />,
      )
    }
  }

  const openBottomSheet2 = (type, additionalFields) => {
    if (type == BottomSheetType.SET_MPIN) {
      setBottomSheet2(
        <BottomSheet open={true} onDismiss={closeBottomSheet2}>
          {" "}
          {<SetMpinBottomSheetV3 />}{" "}
        </BottomSheet>,
      )
    } else if (type == BottomSheetType.RESET_PIN) {
      setBottomSheet(
        <BottomSheet open={true} onDismiss={closeBottomSheet}>
          {<ResetPin_v3 />}
        </BottomSheet>,
      )
    }
  }

  const closeBottomSheet = () => {
    setBottomSheet(null)
    window.scrollTo(0, 0) // for ios scroll issue
  }
  const closeBottomSheet2 = () => {
    setBottomSheet2(null)
    window.scrollTo(0, 0) // for ios scroll issue
  }

  return (
    <BottomSheetContext.Provider
      value={{
        bottomSheet,
        openBottomSheet,
        closeBottomSheet,
        openBottomSheet2,
        closeBottomSheet2,
      }}
    >
      {children}
      {bottomSheet && (
        <div className='bottom-sheet-container'>{bottomSheet}</div>
      )}
      {bottomSheet2 && (
        <div>
          <div
            style={{ visibility: "hidden" }}
            className='bottom-sheet-container-2'
          >
            {bottomSheet2}
          </div>
        </div>
      )}
    </BottomSheetContext.Provider>
  )
}

export const useBottomSheet = () => {
  return useContext(BottomSheetContext)
}
