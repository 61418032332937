import React from "react"
import { Route, Routes } from "react-router-dom"
import Cvp from "../components/Cvp"
import ContextSetting from "../components/ExternalPages/ContextSettingScreen/ContextSetting"
import Login from "../components/ExternalPages/Login"
import CardActive from "../components/ExternalPages/PhysicalCardActivation/PhysicalCardActivation"
import IFrame from "../components/ExternalPages/onboarding/iframe/IFrame"
import ExternalPaths from "./ExternalPaths"

const ExternalRoutes = () => {
  return (
    <Routes>
      <Route
        path={ExternalPaths.CONTEXT_SETTING}
        element={<ContextSetting />}
      />
      <Route path={ExternalPaths.CARD_ACTIVE} element={<CardActive />} />

      <Route path={ExternalPaths.ONBOARDING_IFRAME} element={<IFrame />} />
      <Route path={ExternalPaths.CVP} element={<Cvp />} />
      <Route path={ExternalPaths.LOGIN} element={<Login />} />

      <Route path='*' element={<ContextSetting />} />
    </Routes>
  )
}

export default ExternalRoutes
