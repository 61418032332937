import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Header } from "../../core"
import SearchInput from "./Components/searchInput"
import Filter from "../../svg/v3/filter"
import fire from "../../../assets/images/v3/fire.svg"
import noOffers from "../../../assets/images/v3/noOffers.svg"
import HighlightedMerchantOfferCard from "./Components/highlightedMerchantOfferCard"
import MerchantOfferCard from "./Components/merchantOfferCard"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper/modules"
import SwiperCore from "swiper"
import "swiper/css/pagination"
import "swiper/css"
import { getDifferenceInDays, hexToRGBA } from "../../../utils/functions"
import Cross from "../../svg/v3/Cross"
import ArrowDown from "../../svg/arrowDown"
import moment from "moment"

SwiperCore.use([Pagination])

const AllMerchantOffers = () => {
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  const benefits = useSelector(state => state.benefits)
  const [categoryFilters, setCategoryFilters] = useState([])
  const [search, setSearch] = useState("")
  const [offerExpiryFilter, setOfferExpiryFilter] = useState("")
  const [isInputFocused, setInputFocused] = useState(false)
  const [filteredOffers, setFilteredOffers] = useState([])

  const highlightedMerchantOffers = benefits?.merchantBenefits
    .filter(benefit => {
      return benefit?.tags.includes("Hero Offer")
    })
    .sort((a, b) => a?.displayOrder - b?.displayOrder)

  const allMerchantOffers = benefits?.merchantBenefits
    .filter(benefit => {
      return !benefit?.tags.includes("Hero Offer")
    })
    .sort((a, b) => a?.displayOrder - b?.displayOrder)

  const handleInputChange = value => {
    setSearch(value)
  }

  const handleOfferExpiryFilterChange = filter => {
    setSearch("")
    setFilteredOffers([])
    if (filter === offerExpiryFilter) {
      setOfferExpiryFilter("")
    } else {
      setOfferExpiryFilter(filter)
    }
  }

  useEffect(() => {
    if (search.trim()) {
      setOfferExpiryFilter("")
      setFilteredOffers(
        allMerchantOffers?.filter(offer => {
          return offer?.displayTitle
            .toLowerCase()
            .includes(search.trim().toLocaleLowerCase())
        }),
      )
    }
  }, [search])

  useEffect(() => {
    if (offerExpiryFilter) {
      setFilteredOffers(
        allMerchantOffers?.filter(offer => {
          if (offerExpiryFilter === "EXPIRING_SOON") {
            return getDifferenceInDays(offer?.activeTo) < 7
          } else if (offerExpiryFilter === "NEW") {
            return getDifferenceInDays(moment(), offer?.activeFrom) < 7
          }
        }),
      )
    }
  }, [offerExpiryFilter])

  return (
    <div
      style={{
        minHeight: window.innerHeight,
        backgroundColor: theme.v3.cssVars.leadingWhite,
        position: "relative",
      }}
    >
      <div className='v3-allMerchantOffers-headerWrapper'>
        <Header
          text={t("offers.brandOfferHeader")}
          onBack={async () => {
            window.history.go(-1)
          }}
        />
        <div className='v3-allMerchantOffers-searchBarContainer'>
          <div>
            <SearchInput
              placeholder={!isInputFocused && "Search by category or brand..."}
              value={search}
              onInputChange={handleInputChange}
              focused={isInputFocused}
              setFocused={setInputFocused}
            />
          </div>
        </div>
        {!isInputFocused && allMerchantOffers.length !== 0 && (
          <div className='v3-allMerhantOffers-categoriesContainer'>
            {categoryFilters && categoryFilters.length > 0 && (
              <div
                className={`v3-allMerchantOffers-FilterButton ${categoryFilters.length ? "v3-allMerchantOffers-FilterButton-selected" : ""}`}
                style={{
                  borderColor: categoryFilters.length
                    ? theme.v3.rawColors.secondary.color1
                    : theme.v3.rawColors.tertiaryNeutral.color3,
                  background: categoryFilters.length
                    ? theme.v3.rawColors.secondary.color2
                    : "none",
                }}
              >
                {
                  <Filter
                    color={
                      categoryFilters.length
                        ? theme.v3.rawColors.secondary.color1
                        : theme.v3.rawColors.tertiaryNeutral.color1
                    }
                  />
                }
                {
                  <ArrowDown
                    color={
                      categoryFilters.length
                        ? theme.v3.rawColors.secondary.color1
                        : theme.v3.rawColors.tertiaryNeutral.color1
                    }
                    size='10px'
                  />
                }
              </div>
            )}

            <div
              className={`v3-allMerhantOffers-categoriesContainer-category ${offerExpiryFilter === "NEW" ? "v3-allMerchantOffers-categoriesContainer-selected" : ""}`}
              onClick={() => handleOfferExpiryFilterChange("NEW")}
              style={{
                borderColor:
                  offerExpiryFilter === "NEW"
                    ? theme.v3.cssVars.secondary.color1
                    : theme.v3.cssVars.tertiaryNeutral.color3,
                color:
                  offerExpiryFilter === "NEW"
                    ? theme.v3.cssVars.secondary.color1
                    : theme.v3.cssVars.tertiaryNeutral.color1,
                background:
                  offerExpiryFilter === "NEW"
                    ? theme.v3.cssVars.secondary.color4
                    : "",
              }}
            >
              New{" "}
              {offerExpiryFilter === "NEW" && (
                <div>
                  <Cross color={theme.v3.cssVars.secondary.color1} />
                </div>
              )}
            </div>
            <div
              className={`v3-allMerhantOffers-categoriesContainer-category ${offerExpiryFilter === "EXPIRING_SOON" ? "v3-allMerchantOffers-categoriesContainer-selected" : ""}`}
              onClick={() => handleOfferExpiryFilterChange("EXPIRING_SOON")}
              style={{
                borderColor:
                  offerExpiryFilter === "EXPIRING_SOON"
                    ? theme.v3.cssVars.secondary.color1
                    : theme.v3.cssVars.tertiaryNeutral.color3,
                color:
                  offerExpiryFilter === "EXPIRING_SOON"
                    ? theme.v3.cssVars.secondary.color1
                    : theme.v3.cssVars.tertiaryNeutral.color1,
                background:
                  offerExpiryFilter === "EXPIRING_SOON"
                    ? theme.v3.cssVars.secondary.color4
                    : "",
              }}
            >
              Expiring Soon
              {offerExpiryFilter === "EXPIRING_SOON" && (
                <div>
                  <Cross color={theme.v3.cssVars.secondary.color1} />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {!isInputFocused &&
        !search &&
        !categoryFilters.length &&
        !offerExpiryFilter.length &&
        highlightedMerchantOffers.length !== 0 && (
          <div
            className='v3-allMerchantOffers-highlightedOffers'
            style={{
              background: theme.v3.cssVars.secondary.color5,
            }}
          >
            <div
              className='v3-allMerchantOffers-highlightedOffers-header'
              style={{ color: theme.v3.cssVars.tertiaryNeutral.color1 }}
            >
              <img src={fire} alt='fire' />
              <div>Blazing Bargains</div>
            </div>
            <div className='v3-allMerchantOffers-highlightedOffers-carouselContainer'>
              <Swiper
                spaceBetween={6}
                breakpoints={{
                  320: { slidesPerView: 1.2 },
                  395: { slidesPerView: 1.25 },
                  410: { slidesPerView: 1.3 },
                  440: { slidesPerView: 1.4 },
                }}
                centeredSlides={true}
                modules={[Pagination]}
                pagination={{ clickable: true }}
                initialSlide={highlightedMerchantOffers.length > 2 ? 1 : 0}
              >
                {highlightedMerchantOffers?.map((offer, index) => {
                  return (
                    <SwiperSlide
                      style={{ paddingBottom: "40px", marginRight: "10px" }}
                    >
                      {({ isActive }) => {
                        return (
                          <HighlightedMerchantOfferCard
                            offer={offer}
                            key={index}
                            isActive={isActive}
                          />
                        )
                      }}
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>
          </div>
        )}
      <div className='v3-allMerchantOffers-allMerchantOffers'>
        {!search &&
          !isInputFocused &&
          !offerExpiryFilter &&
          !categoryFilters.length &&
          !filteredOffers.length &&
          !!allMerchantOffers.length && (
            <div
              className='v3-allMerchantOffers-allMerchantOffers-title'
              style={{ color: theme.v3.cssVars.primaryBase.color1 }}
            >
              {t("offers.discoverDealsText")}
            </div>
          )}
        {search && (
          <div className='v3-allMerchantOffers-filteredOffer-text'>
            {filteredOffers.length ? (
              <div>
                <span style={{ color: theme.v3.cssVars.primaryBase.color2 }}>
                  {t("offers.filteredOfferLength", {
                    filteredOffersLength: filteredOffers.length,
                  })}
                </span>{" "}
                '{search}'
              </div>
            ) : (
              <div>
                {t("offers.noOffersText")}{" "}
                <span style={{ color: theme.v3.cssVars.primaryBase.color2 }}>
                  for ‘{search}’
                </span>
              </div>
            )}
          </div>
        )}
        {offerExpiryFilter && !isInputFocused && (
          <div className='v3-allMerchantOffers-filteredOffer-text'>
            {filteredOffers.length
              ? `Showing ${filteredOffers.length} ${offerExpiryFilter === "NEW" ? "new" : "expiring"} offers`
              : `No ${offerExpiryFilter === "NEW" ? "new" : "expiring"} offers found`}
          </div>
        )}
        {
          <div className='v3-allMerchantOffers-allMerchantOffers-offerContainer'>
            {(search || offerExpiryFilter || categoryFilters.length
              ? filteredOffers
              : allMerchantOffers
            ).map((offer, index) => {
              return <MerchantOfferCard offer={offer} key={index} />
            })}
          </div>
        }
        {((isInputFocused && search) ||
          offerExpiryFilter ||
          categoryFilters.length) &&
        !filteredOffers.length ? (
          <div className='v3-allMerchantOffers-noOffers-image'>
            <img src={noOffers} alt='no-offers' />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default AllMerchantOffers
