import DOMPurify from "dompurify"
import React from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { DisplayConfig } from "../../utils/constants"
import { captureEvents } from "../../utils/functions"
import {
  BrandingOnHeaders,
  EventName,
  HeaderContentAlignment,
  PwaVersions,
} from "../../utils/enums"
import { programConfig } from "../../utils/tempData"
import v3HeaderLogo from "../../assets/images/v3/headerLogo.svg"
import v3HeaderAction from "../../assets/images/v3/headerClose.svg"
import HeaderArrow from "../svg/v3/headerArrow"
import Cross from "../svg/v3/Cross"
import Text from "../../nativeBaseReplacements/Text"
import View from "../../nativeBaseReplacements/View"
import ChevronRightIcon from "../../nativeBaseReplacements/ChevronRightIcon"
import ChevronLeftIcon from "../../nativeBaseReplacements/ChevronLeftIcon"

export const Header = ({
  text,
  onBack,
  rightProp,
  rightNavigateURL,
  modalOpenFuntion,
  textColor,
  isHomePage,
  headerBackgroundColor,
  customClassName,
  ...otherProps
}) => {
  const theme = useSelector(state => state.theme)
  const config = useSelector(state => state.config)
  const screen = useSelector(state => state.screen)
  const session = useSelector(state => state.session)

  const navigate = useNavigate()
  let headerData = screen.header

  const versioned_header = version => {
    switch (version) {
      case PwaVersions.V1:
        return null
      case PwaVersions.V2:
        return (
          <>
            <HeaderV2 />
          </>
        )
      case PwaVersions.V2_1:
        return (
          <>
            <HeaderV2 />
          </>
        )
      case PwaVersions.V3:
        return <HeaderV3 />
      default:
        return null
    }
  }

  const HeaderV2 = () => {
    return (
      <View>
        {/* View For Above Header Bar */}

        {headerData?.isEnabled ? (
          <View
            display='none'
            _web={{ maxW: DisplayConfig.MAX_WIDTH, display: "flex" }}
            zIndex='1'
            style={{
              boxShadow: "black 0px 3px 7px -4px",
              backgroundColor: headerData?.color || "#FFFFFF",
            }}
            width='100%'
            flexDirection='row'
            px='12px'
            margin='auto'
          >
            <Text
              flex='2'
              textAlign='left'
              display='flex'
              margin='auto'
              py='10px'
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(config.clientHeaderLeftElement),
                }}
              ></div>
            </Text>

            <Text
              flex='5'
              display='flex'
              margin='auto'
              py='10px'
              justifyContent='center'
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(config.clientHeaderCenterText),
                }}
              ></div>
            </Text>

            <Text
              flex='2'
              display='flex'
              margin='auto'
              py='10px'
              justifyContent='flex-end'
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(config.clientHeaderRightElement),
                }}
              ></div>
            </Text>
          </View>
        ) : (
          <View></View>
        )}

        <View
          width='100%'
          display='flex'
          flexDirection='row'
          alignItems='center'
          shadow={2}
          backgroundColor={
            headerBackgroundColor
              ? headerBackgroundColor
              : theme.headerBackgroundColor || theme.color1
          }
          px='12px'
          pl={isHomePage ? "24px" : "4px"}
          py='16px'
          mb='24px'
          {...otherProps}
        >
          {onBack && (
            <View
              position='absolute'
              left='10px'
              onClick={() => {
                captureEvents({
                  eventName: EventName.BACK_BUTTON_CTA,
                  metadata: { lastEvent: session?.eventFlow },
                })
                onBack()
              }}
              cursor='pointer'
            >
              <ChevronLeftIcon size='24px' color={theme.titleColor || "#fff"} />
            </View>
          )}

          <div
            className={`default-header-heading ${customClassName}`}
            style={{
              color: textColor ? textColor : theme.titleColor || "#fff",
            }}
          >
            {text}
          </div>

          {rightNavigateURL ? (
            <View cursor='pointer' onClick={() => navigate(rightNavigateURL)}>
              {rightProp}
            </View>
          ) : (
            <>
              <View
                onClick={() => modalOpenFuntion(true)}
                cursor='pointer'
                color={theme.color2}
                fontSize='14px'
                lineHeight='20px'
                fontWeight='500'
              >
                {rightProp}
              </View>
              {rightProp === "EMI Table" ? (
                <ChevronRightIcon color={theme.color2} size={6} />
              ) : (
                <></>
              )}
            </>
          )}
        </View>
      </View>
    )
  }

  const HeaderV3 = () => {
    const headerConfig = screen?.pwaHeader
    return (
      <div className='v3-header-container'>
        <div
          style={{ display: session.isCssLoaded ? "flex" : "none" }}
          className='v3-header-top-section'
        >
          {(headerConfig?.brandingOnHeader === BrandingOnHeaders.SINGLE_LOGO ||
            headerConfig?.brandingOnHeader === BrandingOnHeaders.TWO_LOGO) && (
            <div
              className={`v3-header-top-logo ${headerConfig?.logoAlignment === HeaderContentAlignment.CENTER ? "v3-header-top-logo-center" : ""}`}
            >
              <img src={headerConfig?.primaryLogoUrl} alt='primary logo' />
              {headerConfig?.brandingOnHeader ===
                BrandingOnHeaders.TWO_LOGO && (
                <>
                  <div className='v3-header-top-logo-seperator'></div>
                  <img
                    src={headerConfig?.secondaryLogoUrl}
                    alt='secondary logo'
                  />
                </>
              )}
            </div>
          )}
          {headerConfig?.brandingOnHeader === BrandingOnHeaders.TEXT && (
            <div
              className={`v3-header-top-text ${headerConfig?.textAlignment === HeaderContentAlignment.CENTER ? "v3-header-top-text-center" : ""}`}
            >
              {headerConfig?.textContent}
            </div>
          )}
          <div
            onClick={() =>
              (window.location.href = localStorage.getItem("redirectUrl"))
            }
            // style={{ display: "flex", alignItems: "center" }}
            className='v3-header-top-close'
          >
            <Cross color={theme?.v3?.rawColors?.leadingWhite} />
          </div>
        </div>
        {text && (
          <div className='v3-header-bottom-section'>
            <div
              style={{ width: "8%" }}
              onClick={() => {
                captureEvents({
                  eventName: EventName.BACK_BUTTON_CTA,
                  metadata: { lastEvent: session?.eventFlow },
                })
                onBack()
              }}
            >
              <HeaderArrow />
            </div>
            <div
              className='v3-header-text'
              style={{
                width: "92%",
                color: textColor || theme.v3.cssVars.tertiaryNeutral.color1,
              }}
            >
              <span>{text}</span>
            </div>
          </div>
        )}
      </div>
    )
  }
  return versioned_header(config.version)
}
