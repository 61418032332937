import React, {
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import top from "../../../../assets/images/v3/active/homeHeroTopBgImg.svg"
import bottom from "../../../../assets/images/v3/active/homeHeroBottomBgImg.svg"
import ChannelsIcon from "../../../svg/v3/channelsIcon"
import FreezeCardIcon from "../../../svg/v3/freezeCardIcon"
import MoreActionIcon from "../../../svg/v3/moreActionIcon"
import Mpin_v3 from "../../../auth/mpin/Mpin_v3"
import ApiIds from "../../../../auth/ApiIds"
import { handleApiAuth } from "../../../../utils/auth"
import { useSelector, useDispatch } from "react-redux"
import { BottomSheetType } from "../../../../utils/enums"
import CardService from "../../../../services/CardService"
import CardDetails_v3 from "../../../MockSwitch/CardDetails/CardDetails_v3"
import { useNavigate } from "react-router-dom"
import HorizontalCard from "../../../../assets/images/v3/horizontalCard.svg"
import VerticalCard from "../../../../assets/images/v3/VerticalCard.svg"
import frozenCard from "../../../../assets/images/v3/frozenCard.svg"
import verticalFrozenCard from "../../../../assets/images/v3/verticalFrozenCard.svg"
import frozenIcon from "../../../../assets/images/v3/frozenIcon.svg"
import transperentCard from "../../../../assets/images/v3/transperentCard.svg"
import blockedCard from "../../../../assets/images/v3/blocked.svg"
import blockedCardVertical from "../../../../assets/images/v3/blockedCardVertical.svg"
import UnfreezeCard from "../../../svg/v3/unfreezeCard"
import HelpAndSupport from "../../../svg/v3/helpAndSupport"
import UnlockCard from "../../../svg/v3/Card"
import Card from "../../../svg/v3/Card"
import {
  CardActions,
  CardAnimationNames,
  CardOrientation,
  ErrorType,
} from "../../../../utils/constants"
import {
  consoleError,
  captureEvents,
  addClassname,
  removeClassname,
  hideGenericError,
  resetErrorTryAgainCount,
} from "../../../../utils/functions"
import { setCards } from "../../../../store/actions/User"
import { useBottomSheet } from "../../../auth/BottomSheetContext"
import { EventName, Issuers, AU_BlockCodes } from "../../../../utils/enums"
import { setdisabledFunctionsByBlockCode } from "../../../../store/actions/Session"
import "./styles.css"
import moment from "moment"
import { ErrorContext } from "../../../auth/ErrorScreenContext"

const animationName = [
  "CARD_FLASH",
  "TILT_SHAKING",
  "GROW",
  "VIEW_CARD",
  "BUBBLE",
]

const CardDetailsWidget_v3 = ({
  cardOpen,
  setCardOpen,
  optionsOpen,
  setOptionsOpen,
  setBottomSheetType,
}) => {
  const { t } = useTranslation()
  const { setErrorState } = useContext(ErrorContext)
  const [showDetails, setShowDetails] = useState(false)
  const [success, setSucess] = useState(false)
  const [error, setError] = useState(false)
  const [cardDetails, setCardDetails] = useState()
  const [closeClicked, setClose] = useState(false)
  const [isAnimating, setIsAnimating] = useState(false)

  const [theme, authStore, user, config, screen, session] = useSelector(
    state => [
      state.theme,
      state.auth,
      state.user,
      state.config,
      state.screen,
      state.session,
    ],
  )
  const navigate = useNavigate()
  const frontHorizontalCardRef = useRef()
  const frontVirtualCardRef = useRef()
  const tapToViewRef = useRef()
  const iconOverlayImageUrl = theme?.cardDesignUrl
  const cardOrientation = theme?.cardOrientation
  const bottomSheetContext = useBottomSheet()
  const isBackdropEnabled = screen.home.isBackdropEnabled
  const cardOverlayEnabled = screen.home.isOverlayEnabled
  const isCardSettingEnabled =
    screen?.home?.homePageLayout?.isCardSettingsEnabled
  const isVirtualCardEnabled =
    screen?.home?.homePageLayout?.isVirtualCardEnabled

  const dispatch = useDispatch()

  useEffect(() => {
    if (closeClicked) {
      if (cardOrientation === CardOrientation.VERTICAL) {
        removeClassname(
          frontVirtualCardRef.current,
          "v3-virtual-card-flip-first",
        )
        addClassname(frontVirtualCardRef.current, "v3-virtual-card-flip-second")
        removeClassname(tapToViewRef.current, "v3-virtual-card-flip-first")
        addClassname(tapToViewRef.current, "v3-virtual-card-flip-second")
      } else {
        removeClassname(
          frontHorizontalCardRef.current,
          "v3-virtual-card-flip-first",
        )
        addClassname(
          frontHorizontalCardRef.current,
          "v3-virtual-card-flip-second",
        )
        removeClassname(tapToViewRef.current, "v3-virtual-card-flip-first")
        addClassname(tapToViewRef.current, "v3-virtual-card-flip-second")
      }
    }
  }, [closeClicked])

  const authenticateForUnmaskCard = async () => {
    await handleApiAuth({
      apiId: ApiIds.FETCH_UNMASKED_CARD,
      onAuthSuccess: onFetchCardDetailsSuccess,
      onAuthFailure: onAuthFailure,
      onAuthCancel: () => {
        console.log("cancelled")
      },
      otpReason: t("CardDetailsWidgetV3.fetchCardDetails"),
      mpinReason: t("CardDetailsWidgetV3.fetchCardDetails"),
      version: config?.version,
      bottomSheetContext: bottomSheetContext,
    })
  }

  const authenticateForUnfreezeCard = async () => {
    await handleApiAuth({
      apiId: ApiIds.UNLOCK_CARD,
      onAuthSuccess: onFetchUnfreezeCardAuthSuccess,
      onAuthFailure: onUnfreezeCardAuthFailure,
      onAuthCancel: () => {
        console.log("cancelled")
      },
      otpReason: t("CardDetailsWidgetV3.toUnlockCard"),
      mpinReason: t("CardDetailsWidgetV3.toUnlockCard"),
      version: config?.version,
      bottomSheetContext: bottomSheetContext,
    })
  }

  const onFetchCardDetailsSuccess = async apiToken => {
    try {
      setIsAnimating(true)
      const response = await CardService.unMaskCard(apiToken, user.cards[0]?.id)
      const result = response.data

      if (result?.success) {
        if (cardOrientation === CardOrientation.VERTICAL) {
          addClassname(
            frontVirtualCardRef.current,
            "v3-virtual-card-flip-first",
          )
          addClassname(tapToViewRef.current, "v3-virtual-card-flip-first")
        } else {
          addClassname(
            frontHorizontalCardRef.current,
            "v3-virtual-card-flip-first",
          )
          addClassname(tapToViewRef.current, "v3-virtual-card-flip-first")
        }
        setTimeout(() => {
          setCardDetails(result.data)
          setSucess(true)
          setClose(false)
        }, 400)
        setTimeout(() => {
          setIsAnimating(false)
        }, 1000)
        resetErrorTryAgainCount()
      } else {
        if (hideGenericError(response?.status)) {
          setErrorState(response?.status, () => authenticateForUnmaskCard())
        } else {
          onAuthFailure()
        }
        setError(true)
      }
    } catch (error) {
      if (!navigator.onLine) {
        setErrorState(ErrorType.NO_INTERNET_ERROR, () =>
          authenticateForUnmaskCard(),
        )
      } else {
        console.log(error)
        onAuthFailure()
        setError(true)
      }
    }
  }

  const onFetchUnfreezeCardAuthSuccess = async apiToken => {
    try {
      const response = await CardService.unlockCard(apiToken, user.cards[0]?.id)
      const result = response.data
      if (result?.success) {
        const newCard = { ...user.cards[0] }
        newCard.isLocked = false
        dispatch(setCards({ cards: [newCard] }))
        bottomSheetContext.openBottomSheet(
          BottomSheetType.SUCCESS,
          t("CardDetailsWidgetV3.cardUnfrozenMsg"),
          "",
        )
        resetErrorTryAgainCount()
      } else {
        if (hideGenericError(response?.status)) {
          setErrorState(response?.status, () => authenticateForUnfreezeCard())
        } else {
          onUnfreezeCardAuthFailure()
        }
      }
    } catch (e) {
      if (!e.response) {
        setErrorState(ErrorType.NO_INTERNET_ERROR, () =>
          authenticateForUnmaskCard(),
        )
      } else {
        consoleError(e)
        onUnfreezeCardAuthFailure()
      }
    }
  }

  const onUnfreezeCardAuthFailure = (error, message) => {
    consoleError("Unfreeze Card failure", error)
    bottomSheetContext.openBottomSheet(
      BottomSheetType.FAILURE,
      "Something went wrong!",
      "There was a problem in unfreezing your card. Please try again in a while",
      null,
      null,
      () => authenticateForUnfreezeCard(),
    )
  }

  const onAuthFailure = (error, message) => {
    console.error("Authentication failure:", error)
    bottomSheetContext.openBottomSheet(
      BottomSheetType.FAILURE,
      t("CardDetailsWidgetV3.BottomSheetFailureMsg"),
      t("CardDetailsWidgetV3.cardDetailsFetchErrorMsg"),
      null,
      null,
      () => authenticateForUnmaskCard(),
    )
  }

  // for freeze card
  const onLockAuthCancel = async message => {
    message && console.log()
    handleFailureState()
    // navigate("/", { replace: true })
  }

  // for freeze card
  const handleFailureState = () => {
    bottomSheetContext.openBottomSheet(
      BottomSheetType.FAILURE,
      t("CardDetailsWidgetV3.BottomSheetFailureMsg"),
      t("CardDetailsWidgetV3.cardFreezeErrorMsg"),
      null,
      null,
      () => handleFreezeCardClick(),
    )
  }
  const hadnleGoBackToHome = () => {
    bottomSheetContext.closeBottomSheet()
  }
  // Freeze card on auth success
  const card = user.cards?.[0]
  const onLockAuthSuccess = async apiToken => {
    try {
      const response = await CardService.lockCard(apiToken, user.cards[0]?.id)
      const result = response?.data

      if (result?.success) {
        let eventName = EventName.TEMP_LOCK
        captureEvents({ eventName: eventName, metadata: {} })

        const tempCard = { ...card }
        tempCard.isLocked = true
        dispatch(setCards({ cards: [tempCard] }))
        bottomSheetContext.openBottomSheet(
          BottomSheetType.SUCCESS,
          "Your card was frozen successfully!", // needs to be updated ?
          `effective ${moment(new Date()).format("DD-MM-YYYY, hh:mm A")}.`, // needs to be updated ?
          null,
          3000,
          null,
          false,
          false,
          true,
          true,
          "Unfreeze card now",
          "Return to Home",
          null,
          authenticateForUnfreezeCard,
          hadnleGoBackToHome,
        )
        let disabledFunctions = session?.disabledFunctionsByBlockCode
        if (tempCard?.isLocked) {
          if (session?.issuer === Issuers.AU_BANK) {
            let index = disabledFunctions.indexOf(AU_BlockCodes.CARD_LOCKED)
            if (index === -1) {
              disabledFunctions.push(AU_BlockCodes.CARD_LOCKED)
            }
            dispatch(
              setdisabledFunctionsByBlockCode({
                disabledFunctionsByBlockCode: disabledFunctions,
              }),
            )
          }
        } else {
          if (session?.issuer === Issuers.AU_BANK) {
            let index = disabledFunctions.indexOf(AU_BlockCodes.CARD_LOCKED)
            if (index > -1) {
              disabledFunctions.splice(index, 1)
            }
            dispatch(
              setdisabledFunctionsByBlockCode({
                disabledFunctionsByBlockCode: disabledFunctions,
              }),
            )
          }
        }
        resetErrorTryAgainCount()
        // toast("Card settings updated successfully")
        // navigate("/ManageCard", { replace: true })
      } else {
        consoleError(result.errors)
        captureEvents({
          eventName: EventName.CARD_CONTROLS_ERROR,
          metadata: {
            msg: t("CardDetailsWidgetV3.cardDetailsUpdateErrorMsg"),
          },
        })
        // toast(
        //   config.version === PwaVersions.V2_1
        //     ? "Card controls cannot be updated. Please try later"
        //     : "Unable to update Card Controls. Please retry after some time",
        // )
        if (hideGenericError(response?.status)) {
          setErrorState(response?.status, () => handleFreezeCardClick())
        } else {
          handleFailureState()
        }
      }
    } catch (error) {
      if (!navigator.onLine) {
        setErrorState(ErrorType.NO_INTERNET_ERROR, () =>
          handleFreezeCardClick(),
        )
      } else {
        consoleError(error)
        handleFailureState()
      }
    }
  }

  const onLockAuthFailure = async (error, message) => {
    error && consoleError(error)
    message && console.log()
    handleFailureState()
    // navigate("/", { replace: true })
  }

  const handleFreezeCardClick = async () => {
    await handleApiAuth({
      apiId: ApiIds.LOCK_CARD,
      onAuthSuccess: onLockAuthSuccess,
      onAuthFailure: onLockAuthFailure,
      onAuthCancel: onLockAuthCancel,
      otpReason: t("CardDetailsWidgetV3.toChangeCardStatus"),
      mpinReason: t("CardDetailsWidgetV3.toChangeCardStatus"),
      version: config?.version,
      bottomSheetContext: bottomSheetContext,
    })
  }

  const getAnimationClassname = () => {
    const animation = screen?.home?.virtualCardAnimation
    if (
      isVirtualCardEnabled &&
      !user?.cards[0]?.isLocked &&
      !user?.cards[0]?.isHotlisted &&
      !success
    ) {
      switch (animation) {
        case CardAnimationNames.TILT_SHAKING:
          return "animation-tilt-shaking"
        case CardAnimationNames.GROW:
          return "animation-grow"
        default:
          return ""
      }
    } else {
      return ""
    }
  }

  const isStripAnimationEnabled = () => {
    const animation = screen?.home?.virtualCardAnimation
    if (
      isVirtualCardEnabled &&
      !user?.cards[0]?.isLocked &&
      !user?.cards[0]?.isHotlisted &&
      animation === CardAnimationNames.CARD_FLASH &&
      !success
    ) {
      return true
    } else {
      return false
    }
  }

  const cardActions = [
    {
      type: CardActions.CARD_SETTING,
      text: t("CardDetailsWidgetV3.channelAndLimits"),
      onClick: () => navigate("/ManageCard/CardSettings", { replace: true }),
      icon: <ChannelsIcon color={theme.v3.cssVars.primary.color1} />,
      isEnabled:
        !user?.cards[0]?.isLocked &&
        !user?.cards[0]?.isHotlisted &&
        screen?.cardControls?.isEnabled,
    },
    {
      type: CardActions.FREEZE_CARD,
      text: t("CardDetailsWidgetV3.toFreezeCard"),
      onClick: () => {
        setBottomSheetType("freeze")
        handleFreezeCardClick()
      },
      icon: <FreezeCardIcon color={theme.v3.cssVars.primary.color1} />,
      isEnabled:
        !user?.cards[0]?.isLocked &&
        !user?.cards[0]?.isHotlisted &&
        screen?.blockReplaceCard?.isEnabled,
    },
    {
      type: CardActions.MORE_ACTIONS,
      text: t("CardDetailsWidgetV3.moreActions"),
      onClick: () => {
        setBottomSheetType("settings")
        setOptionsOpen(true)
      },
      icon: <MoreActionIcon color={theme.v3.cssVars.primary.color1} />,
      isEnabled: !user?.cards[0]?.isLocked && !user?.cards[0]?.isHotlisted,
    },
    {
      type: CardActions.NEW_CARD,
      text: t("CardDetailsWidgetV3.toRequestNewCard"),
      onClick: () => {
        navigate("/ManageCard/NewCardRequest")
      },
      icon: <Card color={theme.v3.cssVars.primary.color1} />,
      isEnabled:
        user?.cards[0]?.isHotlisted && screen?.blockReplaceCard?.isEnabled,
    },
    {
      type: CardActions.UNFREEZE_CARD,
      text: t("CardDetailsWidgetV3.toUnfreezeCard"),
      onClick: () => {
        authenticateForUnfreezeCard()
      },
      icon: <UnfreezeCard color={theme.v3.cssVars.primary.color1} />,
      isEnabled:
        user?.cards[0]?.isLocked &&
        !user?.cards[0]?.isHotlisted &&
        screen?.blockReplaceCard?.isEnabled,
    },

    {
      type: CardActions.HELP_AND_SUPPORT,
      text: t("CardDetailsWidgetV3.helpAndSupport"),
      onClick: () => {
        navigate("/ManageCard/CustomerCare")
      },
      icon: <HelpAndSupport color={theme.v3.cssVars.primary.color1} />,
      isEnabled:
        user?.cards[0]?.isHotlisted ||
        (user?.cards[0]?.isLocked && !user?.cards[0]?.isHotlisted),
    },
  ]

  const renderAction = action => (
    <div
      className='v3-home-card-details-action-icons'
      onClick={() => action?.onClick()}
    >
      <div className='v3-home-card-details-action-icons-img'>
        {action?.icon}
      </div>
      <div className='v3-home-card-details-action-icons-text'>
        {action?.text}
      </div>
    </div>
  )
  return (
    <div>
      {isVirtualCardEnabled && (
        <div className='v3-home-card-details-bg-top'>
          {isBackdropEnabled ? (
            <img style={{ maxWidth: "100%" }} src={top} alt='Top background' />
          ) : (
            <></>
          )}
        </div>
      )}
      <div className='v3-home-card-details-container'>
        {(isCardSettingEnabled || isVirtualCardEnabled) && (
          <div
            className='v3-home-card-details-title'
            onClick={() => {
              setCardOpen(!cardOpen)
            }}
            style={{ visibility: cardOpen ? "visible" : "hidden" }}
          >
            👇{" "}
            {isVirtualCardEnabled
              ? t("CardDetailsWidgetV3.tapToView")
              : t("CardDetailsWidgetV3.tapToViewSettings")}
          </div>
        )}
        {isVirtualCardEnabled && (
          <div
            className={`${getAnimationClassname()} v3-home-card-details-card`}
            style={{
              boxShadow: isAnimating ? "none" : "0px 10px 31px -2px #00000017",
            }}
          >
            {isStripAnimationEnabled() && (
              <div className='animation-strip'></div>
            )}
            {!success &&
              !user?.cards[0]?.isLocked &&
              !user?.cards[0]?.isHotlisted &&
              cardOverlayEnabled && (
                <div
                  ref={tapToViewRef}
                  className='v3-home-card-details-card-cta '
                  onClick={authenticateForUnmaskCard}
                >
                  {t("CardDetailsWidgetV3.tapToViewDetails")}
                </div>
              )}
            <div
              onClick={() => {
                setShowDetails(!showDetails)
              }}
            >
              {success ? (
                <div>
                  <CardDetails_v3
                    cardDetails={cardDetails}
                    setSucess={setSucess}
                    setClose={setClose}
                    setIsAnimating={setIsAnimating}
                    tapToViewRef={tapToViewRef}
                  />
                </div>
              ) : (
                <>
                  {cardOrientation === CardOrientation.VERTICAL ? (
                    <div
                      ref={frontVirtualCardRef}
                      className='v3-cardDetailsWidget-cardImage-vertical-container'
                    >
                      <img
                        src={
                          iconOverlayImageUrl
                            ? iconOverlayImageUrl
                            : VerticalCard
                        }
                        alt='Card'
                        className='v3-cardDetailsWidget-cardImage-vertical'
                      />
                      {user?.cards[0]?.isHotlisted && (
                        <>
                          <img
                            src={blockedCardVertical}
                            alt='transperant card'
                            className='v3-cardDetailsWidget-frozenCover'
                          />
                          <div
                            className='v3-cardDetailsWidget-frozenCardDetails'
                            style={{ width: "160px" }}
                          >
                            <img src={blockedCard} alt='blocked card' />
                            <div className='v3-cardDetailsWidget-frozenCardDetails-text1'>
                              {t("CardDetailsWidgetV3.cardPermanentBlockedMsg")}{" "}
                            </div>
                            <div className='v3-cardDetailsWidget-frozenCardDetails-text2'>
                              {t("CardDetailsWidgetV3.cardRequestNewCardMsg")}
                            </div>
                          </div>
                        </>
                      )}
                      {user?.cards[0]?.isLocked &&
                        !user?.cards[0]?.isHotlisted && (
                          <>
                            <img
                              src={verticalFrozenCard}
                              alt='frozen card'
                              className='v3-cardDetailsWidget-frozenCover'
                            />
                            <div className='v3-cardDetailsWidget-frozenCardDetails'>
                              <img src={frozenIcon} />
                              <div className='v3-cardDetailsWidget-frozenCardDetails-text1'>
                                {t("CardDetailsWidgetV3.cardFrozenMsg")}{" "}
                              </div>
                              <div className='v3-cardDetailsWidget-frozenCardDetails-text2'>
                                {t("CardDetailsWidgetV3.cardUnfreezeResumeMsg")}
                              </div>
                            </div>
                          </>
                        )}
                    </div>
                  ) : (
                    <div
                      ref={frontHorizontalCardRef}
                      className='v3-cardDetailsWidget-cardImage-horizontal-container'
                    >
                      <img
                        src={
                          iconOverlayImageUrl
                            ? iconOverlayImageUrl
                            : HorizontalCard
                        }
                        alt='Card'
                        className='v3-cardDetailsWidget-cardImage-horizontal'
                      />
                      {user?.cards[0]?.isHotlisted && (
                        <>
                          <img
                            src={transperentCard}
                            alt='transperant card'
                            className='v3-cardDetailsWidget-frozenCover-horizontal'
                          />
                          <div
                            className='v3-cardDetailsWidget-frozenCardDetails'
                            style={{ width: "100%" }}
                          >
                            <img src={blockedCard} alt='blocked card' />
                            <div className='v3-cardDetailsWidget-frozenCardDetails-text1'>
                              {t("CardDetailsWidgetV3.cardPermanentBlockedMsg")}{" "}
                            </div>
                            <div className='v3-cardDetailsWidget-frozenCardDetails-text2'>
                              {t("CardDetailsWidgetV3.cardRequestNewCardMsg")}
                            </div>
                          </div>
                        </>
                      )}
                      {user?.cards[0]?.isLocked &&
                        !user?.cards[0]?.isHotlisted && (
                          <>
                            <img
                              src={frozenCard}
                              alt='frozen card'
                              className='v3-cardDetailsWidget-frozenCover-horizontal'
                            />
                            <div className='v3-cardDetailsWidget-frozenCardDetails'>
                              <img src={frozenIcon} />
                              <div className='v3-cardDetailsWidget-frozenCardDetails-text1'>
                                {t("CardDetailsWidgetV3.cardFrozenMsg")}{" "}
                              </div>
                              <div className='v3-cardDetailsWidget-frozenCardDetails-text2'>
                                {t("CardDetailsWidgetV3.cardUnfreezeResumeMsg")}
                              </div>
                            </div>
                          </>
                        )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
        {isCardSettingEnabled && (
          <div
            className='v3-home-card-details-settings'
            style={{ zIndex: cardOpen ? 10 : 3 }}
          >
            {cardActions?.map((action, index) => {
              if (action.isEnabled) {
                return renderAction(action)
              } else {
                return <></>
              }
            })}
          </div>
        )}
      </div>
      {isVirtualCardEnabled && (
        <div className='v3-home-card-details-bg-bottom'>
          {isBackdropEnabled ? (
            <img src={bottom} alt='Bottom background' />
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  )
}

export default CardDetailsWidget_v3
