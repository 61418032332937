import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { getDifferenceInDays, hexToRGBA } from "../../../../utils/functions"
import merchantOfferCardTag from "../../../../assets/images/v3/merchantOfferCardTag.svg"
import { useSelector } from "react-redux"
import defaultBanner from "../../../../assets/images/v3/BrandBanner.svg"
import defaultLogo from "../../../../assets/images/v3/Vector.svg"

const MerchantOfferCard = ({ offer }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useSelector(state => state.theme)

  const [offerExpiry, setOfferExpiry] = useState(0)

  const handleOnCardClick = () => {
    navigate(`/MerchantOffers/${offer?.benefitId}`)
  }

  useEffect(() => {
    setOfferExpiry(getDifferenceInDays(offer?.activeTo))
  }, [offer])

  return (
    <div
      className='v3-allMerchantOffers-merchantOfferCard'
      style={{ borderColor: theme.v3.cssVars.tertiaryNeutral.color2 }}
      onClick={() => handleOnCardClick()}
    >
      {offerExpiry < 7 && offerExpiry > 0 && (
        <div className='v3-allMerchantOffers-expiryTag'>
          <img
            src={merchantOfferCardTag}
            alt='offer expiry tag'
            className='v3-allMerchantOffers-expiryTag-background'
          />
          <div>
            {t("offers.expiresText")}{" "}
            {offerExpiry < 1 ? "Today" : `in ${offerExpiry.toFixed(0)}d`}
          </div>
        </div>
      )}
      <div className='v3-allMerchantOffers-merchantOfferCard-imageContainer'>
        {offer?.illustrationUrl ? (
          <img src={offer?.illustrationUrl} alt='background' />
        ) : (
          <img src={defaultBanner} />
        )}
        <div>
          {offer?.logoUrl ? (
            <img src={offer?.logoUrl} alt='brand-log' />
          ) : (
            <img src={defaultLogo} width={"35px"} height={"35px"} />
          )}
        </div>
      </div>
      <div className='v3-allMerchantOffers-merchantOfferCard-textContainer'>
        <div style={{ color: theme.v3.cssVars.tertiaryNeutral.color2 }}>
          {offer?.displayTitle}
        </div>
        <div style={{ color: theme.v3.cssVars.tertiaryNeutral.color1 }}>
          {offer?.displayDescription || offer?.description}
        </div>
      </div>
    </div>
  )
}

export default MerchantOfferCard
