import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { hexToRGBA } from "../../../../../../utils/functions"
import { useNavigate } from "react-router-dom"
import defaultLogo from "../../../../../../assets/images/v3/Vector.svg"
import useWindowDimensions from "../../../../../../hooks/useWindowDimensionsWeb"

const BannerCvpCard = ({ benefit }) => {
  const navigate = useNavigate()
  const theme = useSelector(state => state.theme)

  const handleCardClick = () => {
    navigate(`/CvpOffers/${benefit?.benefitId}`)
  }

  const dimension = useWindowDimensions()
  const [updatedDescription, setUpdatedDescription] = useState(
    JSON.parse(benefit?.offerDetails)?.bannerDescription,
  )

  useEffect(() => {
    const description = JSON.parse(benefit?.offerDetails)?.bannerDescription
    if (dimension.width < 360) {
      if (description.length > 19) {
        setUpdatedDescription(description.substring(0, 17) + "...")
      }
    } else if (dimension.width >= 360 && dimension.width <= 390) {
      if (description.length > 22) {
        setUpdatedDescription(description.substring(0, 20) + "...")
      }
    } else {
      if (description.length > 25) {
        setUpdatedDescription(description.substring(0, 23) + "...")
      }
    }
  }, [dimension.width])

  return (
    <div className='v3-offers-bannerCvpCard' onClick={handleCardClick}>
      {benefit?.logoUrl ? (
        <img
          className='v3-offers-bannerCvpCard-logo'
          src={benefit?.logoUrl}
          alt='benefit-logo'
        />
      ) : (
        <img
          src={defaultLogo}
          className='v3-offers-bannerCvpCard-logo'
          style={{ width: "18px" }}
        />
      )}
      <div className='v3-offers-bannerCvpCard-title'>
        {JSON.parse(benefit?.offerDetails)?.bannerTitle}
      </div>
      <div className='v3-offers-bannerCvpCard-description'>
        {updatedDescription}
      </div>
    </div>
  )
}

export default BannerCvpCard
