import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import backgroundPattern from "../../../../assets/images/v3/dashedPattern.svg"
import ProgressBar from "./progressBar"
import DescreteProgressBar from "./descreteProgressBar"
import cardBadge from "../../../../assets/images/v3/campaignOfferCardBadge.svg"
import { useNavigate } from "react-router-dom"
import { getDifferenceInDays, hexToRGBA } from "../../../../utils/functions"
import { useSelector } from "react-redux"
import BadgeCheck from "../../../svg/v3/badgeCheck"
import defaultLogo from "../../../../assets/images/v3/Vector.svg"

const CampaignOfferCard = ({ offer, length }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useSelector(state => state.theme)
  const [progressBarWidth, setProgressBarWidth] = useState(0)
  const [offerExpiry, setOfferExpiry] = useState(0)

  useEffect(() => {
    if (offer?.threshold !== 0) {
      setProgressBarWidth((offer?.currentValue / offer?.threshold) * 100)
    }
    setOfferExpiry(getDifferenceInDays(offer?.activeTo))
  }, [offer])

  let offerDetails
  if (offer) {
    offerDetails = JSON.parse(offer?.offerDetails)
  }

  const handleCardClick = () => {
    navigate(`/CampaignOffers/${offer?.benefitId}`)
  }

  return (
    <div
      className={
        length !== 1 && length
          ? "v3-allCampaignOffers-campaignOfferCard multiple-cards"
          : "v3-allCampaignOffers-campaignOfferCard"
      }
      style={{
        background: offer?.displayColor
          ? offer?.displayColor
          : theme.v3.cssVars.primary.color1,
        marginBottom: length ? "" : "20px",
      }}
      onClick={handleCardClick}
    >
      <div className='v3-allCampaignOffers-campaignOfferCard-backgroundPattern'>
        {offer?.displayColor && (
          <img src={backgroundPattern} alt='dashed-pattern' />
        )}
      </div>
      <div className='v3-allCampaignOffers-campaignOfferCard-badge'>
        <div className='v3-allCampaignOffers-campaignOfferCard-badge-expire'>
          <img src={cardBadge} alt='badge' />
          <div
            className={`v3-allCampaignOffers-campaignOfferCard-badge-expires ${offerExpiry > 1 ? "v3-allCampaignOffers-campaignOfferCard-badge-expires-inDays" : ""}`}
          >
            {t("offers.expiresText")}{" "}
            {offerExpiry < 1
              ? "Today"
              : `in ${offerExpiry.toFixed(0)} ${offerExpiry.toFixed(0) == 1 ? "d" : "d"}`}
          </div>
        </div>
      </div>
      {offer?.logoUrl ? (
        <img
          src={offer?.logoUrl}
          alt='brand-logo'
          className='v3-allCampaignOffers-campaignOfferCard-brandLogo'
        />
      ) : (
        <img
          src={defaultLogo}
          width={"82px"}
          height={"82px"}
          className='v3-allCampaignOffers-campaignOfferCard-brandLogo'
          style={{ background: "white" }}
        />
      )}
      <div className='v3-allCampaignOffers-campaignOfferCard-text'>
        <div
          className='v3-allCampaignOffers-campaignOfferCard-offerDescription'
          style={length > 1 ? { width: "142px" } : { width: "203px" }}
        >
          {offer?.displayDescription || offer?.description}
        </div>
        {offer?.threshold !== 0 && offer?.currentValue < offer?.threshold ? (
          <>
            {offerDetails?.milestoneStyle === "continuous" && (
              <ProgressBar
                progressBarWidth={progressBarWidth}
                height='6px'
                trackColor='rgba(239,239,239,0.4)'
                progressColor={theme.v3.rawColors.leadingWhite}
              />
            )}
            {offerDetails?.milestoneStyle === "descrete" && (
              <DescreteProgressBar
                thresholdValue={offer?.threshold}
                milestoneValue={offer?.currentValue}
                height='6px'
                trackColor='rgba(239,239,239,0.4)'
                progressColor={theme.v3.rawColors.leadingWhite}
              />
            )}
          </>
        ) : offer?.threshold !== 0 ? (
          <div className='v3-allCampaignOffers-campaignOfferCard-milestoneComplete'>
            <div>
              <BadgeCheck color={theme.v3.cssVars.leadingWhite} />
              <span>{t("offers.milestoneCompletionText")}</span>
            </div>
          </div>
        ) : (
          <></>
        )}
        {offerDetails?.pendingText &&
          offer?.currentValue < offer?.threshold && (
            <div className='v3-allCampaignOffers-campaignOfferCard-pendingText'>
              {offerDetails?.pendingText?.replace(
                "{pendingValue}",
                `${offer?.threshold - offer?.currentValue}`,
              )}
            </div>
          )}
        {offerDetails?.subText && (
          <div className='v3-allCampaignOffers-campaignOfferCard-subText'>
            {offerDetails?.subText}
          </div>
        )}
      </div>
    </div>
  )
}

export default CampaignOfferCard
