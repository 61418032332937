import React from "react"
import { useNavigate } from "react-router-dom"
import { hexToRGBA, truncateString } from "../../../../utils/functions"
import { useSelector } from "react-redux"
import defaultBanner from "../../../../assets/images/v3/BrandBannerCVP.svg"
import defaultLogo from "../../../../assets/images/v3/Vector.svg"

const CvpOfferCard = ({ offer }) => {
  const navigate = useNavigate()
  const theme = useSelector(state => state.theme)

  const handleCardClick = () => {
    navigate(`/CvpOffers/${offer?.benefitId}`)
  }

  return (
    <div
      className='v3-allCvpOffers-offerCard'
      onClick={handleCardClick}
      style={{ color: theme.v3.cssVars.primaryBase.color1 }}
    >
      <div className='v3-allCvpOffers-offerCard-banner-container'>
        {offer?.illustrationUrl ? (
          <img
            src={offer?.illustrationUrl}
            className='v3-allCvpOffers-offerCard-banner'
            alt='banner'
          />
        ) : (
          <img
            src={defaultBanner}
            className='v3-allCvpOffers-offerCard-banner'
            width={"100%"}
          />
        )}
        {offer?.logoUrl ? (
          <img
            src={offer?.logoUrl}
            className='v3-allCvpOffers-offerCard-logo'
            alt='merchant-logo'
          />
        ) : (
          <img
            src={defaultLogo}
            className='v3-allCvpOffers-offerCard-logo'
            style={{ background: "white", width: "25%", height: "65%" }}
          />
        )}
      </div>
      <div>
        <div
          className='v3-allCvpOffers-offerCard-tag'
          style={{ color: theme.v3.cssVars.primaryBase.color2 }}
        >
          {truncateString(offer?.displayTitle?.toUpperCase(), 35)}
        </div>
        <div className='v3-allCvpOffers-offerCard-details'>
          {truncateString(offer?.displayDescription, 35) ||
            truncateString(offer?.description, 35)}
        </div>
      </div>
    </div>
  )
}

export default CvpOfferCard
