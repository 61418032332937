import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { ColorButton } from "../../../core/buttons"
import { captureEvents } from "../../../../utils/functions"
import { EventName } from "../../../../utils/enums"
import loginIntroTick from "../../../../assets/images/v3/loginIntroTick.svg"
import bottomDisc from "../../../../assets/images/v3/welcomeScreen/disc.svg"
import background from "../../../../assets/images/v3/welcomeScreen/background.svg"
import fuel from "../../../../assets/images/v3/welcomeScreen/fuel.svg"
import offers from "../../../../assets/images/v3/welcomeScreen/offers.svg"
import bag from "../../../../assets/images/v3/welcomeScreen/bag.svg"
import popcorn from "../../../../assets/images/v3/welcomeScreen/popcorn.svg"
import star from "../../../../assets/images/v3/welcomeScreen/star.svg"
import smallStar from "../../../../assets/images/v3/welcomeScreen/star-small.svg"
import horizontalCard from "../../../../assets/images/v3/horizontalCard.svg"
import verticalCard from "../../../../assets/images/v3/VerticalCard.svg"
import { Header } from "../../../core"
import { CardOrientation } from "../../../../utils/constants"

const SetMpinIntro_v3 = ({ setMpinConfig, onSubmit }) => {
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  const config = useSelector(state => state.config)
  const user = useSelector(state => state.user)
  const screen = useSelector(state => state.screen)
  const slideUpAnimation = localStorage.getItem("firstTimeAuth") || false
  const [slideRightTransition, setSlideRightTransition] = useState(true)

  useEffect(() => {
    captureEvents({ eventName: EventName.WELCOME_PAGE, metadata: {} })
  }, [])

  const handleTransitionAndSubmit = () => {
    setSlideRightTransition(false)
    localStorage.removeItem("firstTimeAuth")
    setTimeout(() => {
      onSubmit()
    }, 500)
  }

  return (
    <div
      style={{
        minHeight: window.innerHeight,
        backgroundColor: theme.v3.cssVars.leadingWhite,
      }}
    >
      <Header />
      <div
        className={`v3-mpinIntro-wrapper ${slideUpAnimation && slideRightTransition ? "v3_mpin_slide_up" : "v3_mpin_slide_right"}`}
        style={{ minHeight: window.innerHeight - 52 }}
      >
        <div className='v3-mpinIntro-top'>
          {/* <img
            src={screen.preLoader.topImageUrl ?? loginIntroTick}
            width={"30px"}
            height={"30px"}
            alt='tick'
          />
          <div className='v3-mpinIntro-header-title'>
            Your card has been approved
          </div> */}
          <div className='v3-mpinIntro-header-desc'>
            {t("mpin.mpinIntroHeaderDesc")} <br></br>
            {user.customer?.firstName + " "}!
          </div>
        </div>
        <div className='v3-mpinIntro-hero-image'>
          <div className='v3-mpinIntro-hero-background'>
            <img src={background} />
            <div
              className={
                theme?.cardOrientation === CardOrientation.HORIZONTAL
                  ? `${screen?.preLoader?.heroImageUrl ? "v3-mpinIntro-hero-card-horizontal" : "v3-mpinIntro-hero-card-horizontal-stock"}`
                  : `${screen?.preLoader?.heroImageUrl ? "v3-mpinIntro-hero-card-vertical" : "v3-mpinIntro-hero-card-vertical-stock"}`
              }
            >
              <img
                src={
                  screen.preLoader.heroImageUrl ??
                  (theme?.cardDesignUrl
                    ? theme?.cardDesignUrl
                    : theme?.cardOrientation === CardOrientation.HORIZONTAL
                      ? horizontalCard
                      : verticalCard)
                }
                style={{ width: "100%" }}
                alt='card'
              />
            </div>
            <img src={fuel} alt='fuel' className='v3-mpinIntro-hero-fuel' />
            <img src={bag} alt='bag' className='v3-mpinIntro-hero-bag' />
            <img
              src={offers}
              alt='offers'
              className='v3-mpinIntro-hero-offers'
            />
            <img
              src={popcorn}
              alt='popcorn'
              className='v3-mpinIntro-hero-popcorn'
            />
            <img
              src={bottomDisc}
              alt='disc'
              className='v3-mpinIntro-hero-disc'
            />
            <img src={star} alt='star' className='v3-mpinIntro-hero-star' />
            <img
              src={smallStar}
              alt='star'
              className='v3-mpinIntro-hero-small-star'
            />
          </div>
        </div>
        <div className='v3-mpinIntro-content'>
          <div className='v3-mpinIntro-content-title'>
            {t("mpin.mpinIntroContentTitle")}
          </div>
          <div className='v3-mpinIntro-content-text-1'>
            {t("mpin.mpinIntroContentText1")}
          </div>
          <div className='v3-mpinIntro-content-text-2'>
            {t("mpin.mpinIntroContentText2")}
          </div>
        </div>
        <div className='v3-mpinIntro-bottom'>
          <div className='v3-mpinIntro-cta-container-text'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='17'
              height='17'
              viewBox='0 0 17 17'
              fill='none'
            >
              <path
                d='M12.4434 6.63881C12.2854 6.25815 11.9314 6.02148 11.5194 6.02148L9.61478 6.06816L10.7588 3.41748C10.8928 3.10748 10.8614 2.75348 10.6761 2.47215C10.4908 2.19015 10.1781 2.02148 9.84077 2.02148H6.85412C6.29545 2.02148 5.83212 2.37082 5.65079 2.91682L3.90612 7.37148C3.80278 7.68015 3.85278 8.00816 4.04345 8.27216C4.23411 8.53616 4.52946 8.68815 4.85546 8.68815L6.73811 8.64415L4.81477 14.2468C4.76477 14.3935 4.82211 14.5555 4.95344 14.6375C5.00811 14.6715 5.06878 14.6882 5.13012 14.6882C5.21612 14.6882 5.30145 14.6548 5.36612 14.5908L12.2274 7.72882C12.5181 7.43749 12.6008 7.01948 12.4434 6.63881Z'
                fill={theme.v3.rawColors.tertiaryPositive.color1}
              />
            </svg>
            {t("mpin.perksAndBenefitsText")}
          </div>
          <div className='v3-mpinIntro-cta-container'>
            <div style={{ width: "100%" }}>
              <ColorButton
                mx='auto'
                text='Set up your space'
                onPress={handleTransitionAndSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SetMpinIntro_v3
