import React from "react"
import { useSelector } from "react-redux"
import { Icon } from "../../nativeBaseReplacements"

const AnalyseSpends = () => {
  const theme = useSelector(state => state.theme)
  return (
    <Icon viewBox='0 0 15 20' size='32px'>
      <svg
        width='23'
        height='26'
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M7.86842 16.3333H5.44737V8.16667H7.86842V16.3333ZM12.7105 16.3333H10.2895V4.66667H12.7105V16.3333ZM17.5526 16.3333H15.1316V11.6667H17.5526V16.3333ZM20.5789 18.7833H2.42105V2.33333H20.5789V18.7833ZM20.5789 0H2.42105C1.08947 0 0 1.05 0 2.33333V18.6667C0 19.95 1.08947 21 2.42105 21H20.5789C21.9105 21 23 19.95 23 18.6667V2.33333C23 1.05 21.9105 0 20.5789 0Z'
          fill={theme.appTextColor}
        />
      </svg>
    </Icon>
  )
}

export default AnalyseSpends
