import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import Close_v3 from "../../../svg/v3/close"
import statement from "../../../../assets/images/v3/active/statementdefault.svg"
import resetPin from "../../../../assets/images/v3/resetPin.svg"
import resetMpin from "../../../../assets/images/v3/resetMpin.svg"
import blockReplace from "../../../../assets/images/v3/blockReplace.svg"
import customerCare from "../../../../assets/images/v3/customerCare.svg"
import aboutCard from "../../../../assets/images/v3/aboutCard.svg"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { setSetMpinState } from "../../../../store/actions/SetMpin"
import { BottomSheetType } from "../../../../utils/enums"
import { useBottomSheet } from "../../../auth/BottomSheetContext"

const BottomSheet_v3 = ({ setOptionsOpen }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const screen = useSelector(state => state.screen)

  const settingsHomeBottomContainerRef = useRef(null)
  const bottomSheetContext = useBottomSheet()

  const dispatch = useDispatch()
  const onChangeMpinClick = async () => {
    setOptionsOpen(false)
    bottomSheetContext.openBottomSheet2(BottomSheetType.SET_MPIN)
    dispatch(
      setSetMpinState({
        onSetMpinSuccess: async () => {},
        onSetMpinFailure: async () => {
          // toast("Failed to set MPIN")
          navigate("/", { replace: true })
        },
        onSetMpinCancel: async () => {
          navigate("/", { replace: true })
        },
      }),
    )
    // navigate to set mpin screen
    // navigate("/Auth/SetMpin", { replace: true, state: { isChangeMpin: true } })
  }

  useEffect(() => {
    const element = settingsHomeBottomContainerRef.current
    setTimeout(() => {
      element.classList.add("active")
    }, 3)
    return () => {
      element.classList.remove("active")
    }
  }, [])

  const mpinWord = screen?.mpin?.setMpin?.mpinCharacterCase || "mPIN"

  const settings = [
    {
      logo: statement,
      title: t("settings.StatementTitle"),
      desc: t("settings.StatementDescription"),
      navigateTo: "/statement",
      isEnabled: screen?.aboutProgram?.isEnabled,
    },
    {
      logo: resetPin,
      title: t("settings.ResetPinTitle"),
      desc: t("settings.ResetPinDescription"),
      callBottomSheetFunction: () => {
        setOptionsOpen(false)
        bottomSheetContext.openBottomSheet2(BottomSheetType.RESET_PIN)
      },
      isEnabled: screen?.mpin?.changeCardPin?.isEnabled,
    },
    {
      logo: resetMpin,
      // title: `Reset ${mpinWord}`,
      // desc: "Change your Mobile PIN",
      title: t("settings.ResetMpinTitle", {
        mpinWord: mpinWord,
      }),
      desc: t("settings.ResetMpinDescrption"),
      callBottomSheetFunction: () => {
        onChangeMpinClick()
      },
      isEnabled: screen?.mpin?.resetMpin?.isEnabled,
    },
    // {  // not req currently
    //   logo: cardSettings,
    //   title: "Manage Saved Cards",
    //   desc: "Add/remove merchants linked to your card",
    //   navigateTo: "/ManageCard/CardSettings",
    // },
    {
      logo: customerCare,
      title: t("settings.CustomerCareTitle"),
      desc: t("settings.CustomerCareDescription"),
      navigateTo: "/ManageCard/CustomerCare",
      isEnabled: screen?.customerCare?.isEnabled,
    },
    {
      logo: aboutCard,
      title: t("settings.AboutCardTitle"),
      desc: t("settings.AboutCardDescription"),
      navigateTo: "/ManageCard/AboutProgram",
      isEnabled: screen?.aboutProgram?.isEnabled,
    },
    {
      logo: blockReplace,
      title: t("settings.BlockReplaceTitle"),
      desc: t("settings.BlockReplaceDescription"),
      navigateTo: "/ManageCard/BlockCardReason",
      isEnabled: screen?.blockReplaceCard?.isEnabled,
    },
  ]

  return (
    <div
      ref={settingsHomeBottomContainerRef}
      className='v3-settings-home-bottom-container'
    >
      <div className='v3-settings-home-bottom'>
        <div className='v3-settings-home-bottom-head'>
          <div>{t("settings.moreCardActions")}</div>
          <div onClick={() => setOptionsOpen(false)}>
            <Close_v3 />
          </div>
        </div>

        {settings.map(item => {
          if (item?.isEnabled) {
            return (
              <div
                className='v3-settings-home-bottom-item'
                onClick={() =>
                  item.callBottomSheetFunction
                    ? item.callBottomSheetFunction()
                    : navigate(item.navigateTo)
                }
              >
                <div className='v3-settings-home-bottom-item-icon'>
                  <img src={item.logo} width={"40px"} height={"40px"} />
                </div>
                <div className='v3-settings-home-bottom-item-body'>
                  <div className='v3-settings-home-bottom-item-body-title'>
                    {item.title}
                  </div>
                  <div className='v3-settings-home-bottom-item-body-desc'>
                    {item.desc}
                  </div>
                </div>

                <div className='v3-settings-home-bottom-item-cta'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                  >
                    <path
                      d='M11.354 8.35403L6.35403 13.354C6.30757 13.4005 6.25242 13.4373 6.19173 13.4625C6.13103 13.4876 6.06598 13.5006 6.00028 13.5006C5.93458 13.5006 5.86953 13.4876 5.80883 13.4625C5.74813 13.4373 5.69298 13.4005 5.64653 13.354C5.60007 13.3076 5.56322 13.2524 5.53808 13.1917C5.51294 13.131 5.5 13.066 5.5 13.0003C5.5 12.9346 5.51294 12.8695 5.53808 12.8088C5.56322 12.7481 5.60007 12.693 5.64653 12.6465L10.2934 8.00028L5.64653 3.35403C5.55271 3.26021 5.5 3.13296 5.5 3.00028C5.5 2.8676 5.55271 2.74035 5.64653 2.64653C5.74035 2.55271 5.8676 2.5 6.00028 2.5C6.13296 2.5 6.26021 2.55271 6.35403 2.64653L11.354 7.64653C11.4005 7.69296 11.4374 7.74811 11.4626 7.80881C11.4877 7.86951 11.5007 7.93457 11.5007 8.00028C11.5007 8.06599 11.4877 8.13105 11.4626 8.19175C11.4374 8.25245 11.4005 8.30759 11.354 8.35403Z'
                      fill='#2680EA'
                    />
                  </svg>
                </div>
              </div>
            )
          } else {
            return <></>
          }
        })}
      </div>
    </div>
  )
}

export default BottomSheet_v3
