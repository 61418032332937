import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation, useNavigate } from "react-router-dom"
import ApiIds from "../../../../auth/ApiIds"
import AccountService from "../../../../services/AccountService"
import {
  clearCommonState,
  setCommonState,
} from "../../../../store/actions/Common"
import { handleApiAuth } from "../../../../utils/auth"
import {
  DisplayConfig,
  TRANSACTION_LOGO_HEIGHT,
  TRANSACTION_LOGO_WIDTH,
} from "../../../../utils/constants"
import {
  captureEvents,
  consoleError,
  formatAmount,
  getTransactionDate,
} from "../../../../utils/functions"
import { EmbeddedLink, Header } from "../../../core"
import { ColorButton } from "../../../core/buttons"
import { showToast1 } from "../../../core/toast"
import TransactionLogo from "../../../core/transactionLogo"
import ErrorScreen from "../../../messages/errorScreen"
import styles from "./styles"
import "./styles.css"

import { Checkbox } from "../../../../core"
import { EventName, PwaVersions } from "../../../../utils/enums"
import ConvertEmiLoader from "../../../core/SkeletonLoader/convertEmiLoader"
import Settings from "../../../svg/settings"
import { useToast } from "../../../../nativeBaseReplacements/useToast"
import ChevronLeftIcon from "../../../../nativeBaseReplacements/ChevronLeftIcon"
import useWindowDimensions from "../../../../hooks/useWindowDimensionsWeb"

const EMI_CONVERTED_ERROR_STRING = /is previously converted to EMI/

function EMIPlans_v2() {
  const { t } = useTranslation()
  const windowDim = useWindowDimensions()
  const location = useLocation()
  const navigate = useNavigate()
  const nativeToast = useToast()
  const dispatch = useDispatch()

  const theme = useSelector(state => state.theme)
  const common = useSelector(state => state.common)
  const user = useSelector(state => state.user)
  const config = useSelector(state => state.config)
  const screen = useSelector(state => state.screen)

  const { isEmiPlansSet, txnState, emiPlansData } = common
  const [value, setValue] = useState()
  const [currentPlan, setCurrentPlan] = useState()
  const [emiPlans, setEmiPlans] = useState([])
  const [error /*, setError */] = useState(false)
  const [loading, setLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [isTncChecked, setIsTncChecked] = useState(false)

  const account = user.account
  const card = user.cards?.[0]
  const isAccountActive =
    account.status === "ACTIVE" || account.status === "DORMANT"
  const [isProcessingFeeSame, setisProcessingFeeSame] = useState(true)

  useEffect(() => {
    let data = {}
    captureEvents({ eventName: EventName.CONVERT_TO_EMI, metadata: data })
  }, [])

  useEffect(() => {
    ;(async () => {
      if (!isEmiPlansSet || location?.state?.txnId != txnState?.txnId) {
        dispatch(clearCommonState())
        dispatch(setCommonState({ txnState: location.state }))

        await handleApiAuth({
          apiId: ApiIds.EMI_CONFIG,
          onAuthSuccess: onEmiConfigAuthSuccess(location.state?.amount),
          onAuthFailure: onAuthFailure,
          onAuthCancel: onAuthCancel,
          otpReason: t("EmiElementV2.emiConfigReason"),
          mpinReason: t("EmiElementV2.emiConfigReason"),
          toast,
          navigate,
        })
      } else {
        let emi_plans = emiPlansData.emiPlans
        // emi_plans.sort(sortByTenure)
        setEmiPlans(emi_plans)

        // setValue(emiPlansData.emiPlans[0]["tenureInMonths"])
        setCurrentPlan(emiPlansData.emiPlans[0])
      }
      setLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmiPlansSet])

  useEffect(() => {
    if (!emiPlans || emiPlans.length == 0) return
    console.log(emiPlans)
    let processingFee = emiPlans[0].processingFee
    emiPlans.forEach(plan => {
      console.log(plan)
      if (plan.processingFee == processingFee) setisProcessingFeeSame(false)
    })
  }, [emiPlans])

  function sortByTenure(a, b) {
    if (a.tenureInMonths < b.tenureInMonths) {
      return 1
    }
    if (a.tenureInMonths > b.tenureInMonths) {
      return -1
    }
    return 0
  }

  const onEmiConfigAuthSuccess = amount => async apiToken => {
    try {
      const response = await AccountService.getEMIConfig(
        apiToken,
        user.account.id,
        amount,
        location.state.txnId,
      )
      const result = response.data

      if (result?.success) {
        dispatch(
          setCommonState({
            isEmiPlansSet: true,
            emiPlansData: result.data,
          }),
        )

        navigate("/ConvertToEMI", { replace: true })
      } else {
        consoleError(result?.errors)
        if (EMI_CONVERTED_ERROR_STRING.test(result?.errors)) {
          toast(t("EmiElementV2.emiTxnConvertedErrorToast"))
        } else {
          toast(t("EmiElementV2.errorToastMesg"))
        }
        navigate("/", { replace: true })
      }
    } catch (error) {
      consoleError(error)
      toast(t("EmiElementV2.errorToastMesg"))
      navigate("/", { replace: true })
    }
  }

  const onAuthFailure = async (error, message) => {
    error && consoleError(error)
    message && toast(message)
    navigate("/", { replace: true })
  }

  const onAuthCancel = async message => {
    message && toast(message)
    navigate("/", { replace: true })
  }

  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  const onCreateEmiAuthSuccess = async apiToken => {
    try {
      const data = {
        tenureInMonths: value,

        txnRefId: txnState?.txnId,
        emiStrategyId: currentPlan.emiStrategyId,
      }

      const response = await AccountService.createEMI(
        apiToken,
        user.account.id,
        data,
      )
      const result = response?.data

      if (result?.success) {
        let metadata = {
          tenure: Number(value),
        }
        captureEvents({
          eventName: EventName.CONVERT_TO_EMI_REQUESTED,
          metadata: metadata,
        })
        if (txnState?.txnType === "AUTHORIZE") {
          toast(t("EmiElementV2.emiConversionReqToastMesg"))
        } else {
          toast(t("EmiElementV2.conversionSuccessToastMesg"))
        }
        navigate("/", { replace: true })
      } else {
        consoleError(result.errors)
        if (EMI_CONVERTED_ERROR_STRING.test(result?.errors)) {
          toast(t("EmiElementV2.emiTxnConvertedErrorToast"))
        } else {
          toast(t("EmiElementV2.convertToEmiErrorToast"))
        }
        navigate("/", { replace: true })
      }
    } catch (error) {
      consoleError(error)
      toast(t("EmiElementV2.convertToEmiErrorToast"))
      navigate("/", { replace: true })
    }
  }

  const handleConvertToEMI = async () => {
    setSubmitLoading(true)

    await handleApiAuth({
      apiId: ApiIds.CREATE_EMI,
      onAuthSuccess: onCreateEmiAuthSuccess,
      onAuthFailure: onAuthFailure,
      onAuthCancel: onAuthCancel,
      otpReason: t("EmiElementV2.emiCreateReason"),
      mpinReason: t("EmiElementV2.emiCreateReason"),
      toast,
      navigate,
    })

    setSubmitLoading(false)
  }

  const getVersionedDisplay = () => {
    return (
      config?.version === PwaVersions.V2 || config?.version === PwaVersions.V2_1
    )
  }

  return (
    <div
      style={
        getVersionedDisplay
          ? {
              minHeight: windowDim.height,
              backgroundColor: theme.backgroundColor,
              maxWidth: DisplayConfig.MAX_WIDTH,
            }
          : {}
      }
      className='emiPlans'
    >
      {error ? (
        <ErrorScreen />
      ) : loading ? (
        <div>
          <ConvertEmiLoader />
        </div>
      ) : (
        <div className='emiPlans-main-div'>
          <div
            className='emiPlans-header-div'
            style={{ maxWidth: DisplayConfig.MAX_WIDTH }}
          >
            {getVersionedDisplay() ? (
              <Header
                text='Convert to EMI'
                onBack={async () => {
                  window.history.go(-1)
                }}
                rightNavigateURL={
                  isAccountActive && card ? "/ManageCard" : null
                }
                rightProp={<Settings />}
              />
            ) : (
              <div
                className='emiPlans-header'
                style={{
                  backgroundColor: getVersionedDisplay()
                    ? theme.headerBackgroundColor
                    : null,
                }}
              >
                <div className='emiPlans-header-leftIcon'>
                  <Link
                    style={{
                      textDecoration: "None",
                    }}
                    to={txnState?.prevPath}
                  >
                    <ChevronLeftIcon
                      size='24px'
                      color={
                        getVersionedDisplay() ? theme.titleColor : "#303030"
                      }
                      width='fit-content'
                    />
                  </Link>
                </div>
                <div className='emiPlans-header-text-div'>
                  <div
                    className='emiPlans-header-text'
                    style={{
                      color: getVersionedDisplay()
                        ? theme.titleColor
                        : "#303030",
                      fontFamily: theme.fontFamily,
                    }}
                  >
                    {t("EmiElementV2.convertToEmiHeaderText")}
                  </div>
                </div>
              </div>
            )}

            <div className='emiPlans-tax-div'>
              <div
                className='emiPlans-tax-widget'
                style={{ backgroundColor: theme.widgetBackgroundColor }}
              >
                <div className='emiPlans-tax-logo-div'>
                  <div className='emiPlans-tax-logo'>
                    {txnState?.logo ? (
                      <img
                        {...styles.image}
                        src={`data:image/jpg;base64,${txnState?.logo}`}
                        alt='logo'
                        width={TRANSACTION_LOGO_WIDTH + "px"}
                        height={TRANSACTION_LOGO_HEIGHT + "px"}
                      />
                    ) : (
                      <div className='emiPlans-tax-transactionLogo'>
                        <TransactionLogo transactionType={txnState?.txnType} />
                      </div>
                    )}
                  </div>
                  <div className='emiPlans-tax-description-div'>
                    <div className='emiPlans-tax-description-text-div'>
                      <div
                        className='emiPlans-tax-description-text'
                        style={{
                          color: getVersionedDisplay()
                            ? theme.appTextColor
                            : null,
                          fontFamily: theme.fontFamily,
                        }}
                      >
                        {txnState?.description}
                      </div>
                    </div>
                    <div className='emiPlans-tax-date-text-div'>
                      <div
                        className='emiPlans-tax-date-text'
                        style={{
                          color: getVersionedDisplay()
                            ? `${theme.appTextColor}90`
                            : null,
                          fontFamily: theme.fontFamily,
                        }}
                      >
                        {getTransactionDate(
                          txnState?.transactionDate,
                          txnState?.postingDate,
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='emiPlans-tax-amount-text-div'>
                  <div
                    className='emiPlans-tax-amount-text'
                    style={{
                      fontFamily: theme.fontFamily,
                      color: getVersionedDisplay() ? theme.appTextColor : null,
                    }}
                  >
                    {formatAmount(txnState?.amount)}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className='emiPlans-choosePlan-widget'
            style={{
              backgroundColor: theme.widgetBackgroundColor,
              marginBottom: "170px",
            }}
          >
            <div className='emiPlans-choosePlan-header'>
              <div className='emiPlans-choosePlan-header-div'>
                <div
                  className='emiPlans-choosePlan-header-title-text'
                  style={{
                    color: getVersionedDisplay() ? theme.appTextColor : null,
                  }}
                >
                  {t("EmiElementV2.choosePlanCTA")}
                </div>
                <div
                  className='emiPlans-choosePlan-header-subTitle-text'
                  style={{
                    color: getVersionedDisplay()
                      ? `${theme.appTextColor}90`
                      : null,
                  }}
                >
                  {isProcessingFeeSame
                    ? t("EmiElementV2.processingFeeSame", {
                        processingFees: currentPlan.processingFees,
                      })
                    : t("EmiElementV2.processingFeeNotSame")}
                </div>
              </div>
            </div>
            <div
              style={{
                tableLayout: "fixed",
                width: "100%",
                overflow: "scroll",
              }}
            >
              <table
                style={{
                  tableLayout: "fixed",
                  width: "100%",
                  overflow: "scroll",
                }}
              >
                <tr>
                  <th className='table-header' style={{ width: "10px" }}></th>
                  <th className='table-header' style={{ width: "50px" }}>
                    {t("EmiElementV2.duration")}
                  </th>
                  <th className='table-header'>{t("EmiElementV2.rate")}</th>
                  <th className='table-header'>{t("EmiElementV2.interest")}</th>
                  <th className='table-header'>{t("EmiElementV2.emi")}</th>
                </tr>
                {emiPlans &&
                  emiPlans.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        width: "10px",
                        backgroundColor:
                          value === item["tenureInMonths"]
                            ? `${theme.color1}30`
                            : index % 2 === 0
                              ? "#FFFFFF"
                              : "#F9F9F9",
                        borderRadius:
                          index === emiPlans.length - 1 ? "0 0 8px 8px" : "0",
                      }}
                    >
                      <td
                        style={
                          index === emiPlans?.length - 1
                            ? { borderBottomLeftRadius: "12px" }
                            : {}
                        }
                        className='table-cell'
                      >
                        <input
                          type='radio'
                          name='fav_language'
                          value={item}
                          style={{ accentColor: theme.color1 }}
                          onChange={el => {
                            setValue(item["tenureInMonths"])
                            setCurrentPlan(item)
                          }}
                        />
                        {/* <div className='input_internal'></div> */}
                      </td>
                      <td
                        className='table-cell'
                        style={{ width: "50px" }}
                      >{`${item.tenureInMonths} Months`}</td>
                      <td className='table-cell'>{`${
                        item.interestRateBps / 100
                      }%`}</td>
                      <td className='table-cell'>
                        {`${formatAmount(
                          item.emi * item.tenureInMonths - txnState?.amount,
                        )}`}
                      </td>
                      <td
                        style={
                          index === emiPlans?.length - 1
                            ? { borderBottomRightRadius: "12px" }
                            : {}
                        }
                        className='table-cell'
                      >{`₹${item.emi}`}</td>
                    </tr>
                  ))}
              </table>
            </div>
          </div>

          <div className='emiPlans-tnc-div'>
            <div
              className='emiPlans-tnc-div-1'
              style={{ maxWidth: DisplayConfig.MAX_WIDTH }}
            >
              {/* {getVersionedDisplay() && screen?.emi?.isTncEnabled && (
                <div className='emiPlans-tnc-text-div'>
                  <div
                    className='emiPlans-tnc-text'
                    style={{
                      color: getVersionedDisplay() ? theme.appTextColor : null,
                      fontFamily: theme.fontFamily,
                    }}
                  >
                    By continuing you agree to the
                  </div>
                  <div
                    className='emiPlans-tnc-link'
                    ml='4px'
                    onClick={() =>
                      (window.location.href =
                        screen?.emi?.isTncEnabled || "https://hyperface.co.in")
                    }
                  >
                    <div
                      className='emiPlans-tnc-text-2'
                      style={{
                        color: getVersionedDisplay()
                          ? theme.appTextColor
                          : null,
                        fontFamily: theme.fontFamily,
                      }}
                    >
                      Terms & Conditions{" "}
                    </div>
                  </div>
                </div>
              )} */}

              {screen?.emi?.isTncEnabled && (
                <div
                  className='setMpin-tnc'
                  style={{ maxWidth: DisplayConfig.MAX_WIDTH }}
                >
                  <div
                    className='setMpin-tnc-container'
                    style={{
                      backgroundColor:
                        config?.version === PwaVersions.V2 ||
                        config?.version === PwaVersions.V2_1
                          ? theme.widgetBackgroundColor
                          : "#FFFFFF",
                    }}
                  >
                    <div
                      onClick={() => setIsTncChecked(!isTncChecked)}
                      className='setMpin-tnc-checkbox'
                    >
                      <Checkbox
                        marginLeft='-3px'
                        {...styles.tncCheckBox}
                        className={
                          isTncChecked
                            ? "setMpin-tnc-checkBox-checked"
                            : "setMpin-tnc-checkBox-notchecked"
                        }
                        checkmarkClassName={
                          isTncChecked
                            ? "setMpin-tnc-checkMark-checked"
                            : "setMpin-tnc-checkMark-notchecked"
                        }
                        isChecked={isTncChecked}
                        border={"1px solid blue"}
                        style={{
                          backgroundColor: isTncChecked ? theme.color1 : null,
                        }}
                      />
                    </div>
                    <div
                      className='setMpin-tnc-text'
                      style={{ color: theme.appTextColor }}
                    >
                      <EmbeddedLink text={screen?.emi?.tncUrl} />
                    </div>
                  </div>
                </div>
              )}
              <ColorButton
                className='emiPlans-cta-btn'
                style={{ width: "90%", borderRadius: "6px", margin: "auto" }}
                text={
                  config?.version === PwaVersions.V2_1
                    ? t("EmiElementV2.submitBtn")
                    : t("EmiElementV2.convertToEmiHeaderText")
                }
                isDisable={
                  (screen?.emi?.isTncEnabled && !isTncChecked) || !value
                }
                isLoading={submitLoading}
                onPress={() => handleConvertToEMI()}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default EMIPlans_v2
