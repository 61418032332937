export const FLOAT_REGEX = /^\d*\.?\d*$/
export const INTEGER_REGEX = /^\d*?\d*$/
export const EXTERNAL_PATH_ROOT = "external"
export const PHYSICAL_CARD_ACTIVATION_PATH = "PhysicalCardActivation"
export const TRANSACTION_LOGO_WIDTH = 50
export const TRANSACTION_LOGO_HEIGHT = 50
export const DEFAULT_OTP_SMS_SIZE = 4
export const DEFAULT_OTP_EMAIL_SIZE = 4
export const DEFAULT_MPIN_SIZE = 6
export const HEADER_HEIGHT_IN_PX = 70
export const REWARD_TRANSACTIONS_PER_PAGE = 11
export const CASHBACK_TRANSACTIONS_PER_PAGE = 11
export const ERROR_SCREEN_TRY_AGAIN_RETRY_COUNT = 3

export const Envs = Object.freeze({
  DEV: "dev",
  UAT: "uat",
  SANDBOX: "sandbox",
  PROD: "prod",
  AU_SANDBOX: "au-sandbox",
})

export const ProgramTypes = Object.freeze({
  CREDIT_CARD: "CREDIT_CARD",
  PAY_LATER: "PAY_LATER",
  PREPAID: "PREPAID",
  OTHER: "OTHER",
})

export const CardOrientation = Object.freeze({
  HORIZONTAL: "HORIZONTAL",
  VERTICAL: "VERTICAL",
})

export const AuthChallenges = Object.freeze({
  OTP_SMS: "OTP_SMS",
  OTP_EMAIL: "OTP_EMAIL",
  MPIN: "MPIN",
  VERIFY_CUSTOMER: "VERIFY_CUSTOMER",
})

export const AuthScopes = Object.freeze({
  FIRST_FACTOR: "FIRST_FACTOR",
  SECOND_FACTOR: "SECOND_FACTOR",
  STEP_UP: "STEP_UP",
})

export const EmiStatus = Object.freeze({
  NOT_CREATED: "NOT_CREATED",
  PENDING: "PENDING",
  ACTIVE: "ACTIVE",
  CANCELLED: "CANCELLED",
  FORECLOSED: "FORECLOSED",
  CLOSED: "CLOSED",
})

export const OtpErrors = Object.freeze({
  RETRIES_EXCEEDED: "RETRIES_EXCEEDED",
  BLOCKED_TEMPORARILY: "BLOCKED_TEMPORARILY",
  INVALID_OTP: "INVALID_OTP",
  OTP_EXPIRED: "OTP_EXPIRED",
  TEMPORARILY_BLOCK: "TEMPORARILY_BLOCK",
  INVALID_API_TOKEN: "Invalid apiToken",
})

export const MpinErrors = Object.freeze({
  INCORRECT_MPIN: "INCORRECT_MPIN",
  BLOCKED: "BLOCKED",
  MISMATCH_MPIN: "MISMATCH_MPIN",
  OLD_MPIN_MATCH: "OLD_MPIN_MATCH",
})

export const TransactionDateTypes = Object.freeze({
  TRANSACTION_DATE: "TRANSACTION_DATE",
  POSTING_DATE: "POSTING_DATE",
})

export const DisplayConfig = Object.freeze({
  MAX_WIDTH: "450px",
})

export const PWA_MAX_WIDTH_IN_PX = 450

//Merchant Category Codes

export const FUEL = [5172, 5541, 5542, 5552, 5983]

export const UTILITY = [4812, 4814, 4899, 4900]

export const DINING = [5811, 5812, 5813, 5814]

export const GROCERY = [5411, 5422, 5441, 5451, 5462, 5499, 5921]

export const ELECTRONICS = [4816, 4812, 5722, 5044, 5045, 5732, 5734, 7622]

export const CASH_WITHDRAWAL = [6010, 6011]

export const TRAVEL = [
  3000, 3001, 3004, 3005, 3006, 3007, 3008, 3009, 3010, 3011, 3012, 3013, 3014,
  3015, 3016, 3017, 3018, 3020, 3021, 3022, 3023, 3024, 3025, 3026, 3027, 3028,
  3029, 3030, 3031, 3032, 3034, 3035, 3037, 3038, 3039, 3040, 3042, 3043, 3044,
  3045, 3047, 3048, 3049, 3050, 3051, 3052, 3054, 3058, 3061, 3064, 3066, 3068,
  3069, 3071, 3072, 3075, 3076, 3077, 3078, 3079, 3082, 3083, 3084, 3085, 3088,
  3089, 3090, 3096, 3098, 3099, 3100, 3102, 3103, 3111, 3112, 3115, 3127, 3129,
  3132, 3136, 3144, 3146, 3156, 3161, 3167, 3171, 3174, 3175, 3177, 3180, 3181,
  3182, 3183, 3184, 3186, 3187, 3190, 3191, 3193, 3196, 3200, 3204, 3206, 3211,
  3212, 3213, 3217, 3219, 3220, 3221, 3228, 3234, 3236, 3240, 3245, 3246, 3247,
  3248, 3252, 3256, 3259, 3260, 3261, 3266, 3267, 3280, 3282, 3285, 3286, 3287,
  3294, 3295, 3297, 3298, 3299, 3300, 3301, 4511, 4722, 4723, 4112, 4131,
]

export const RENT = [6513]

export const APPAREL = [
  5611, 5621, 5631, 5641, 5651, 5655, 5661, 5698, 5699, 5139, 5697, 5941, 7296,
]

export const rewardsSectionTempConfig = {
  heroTitle: "",
  showTotalEarned: true,
  showTotalRedeemed: true,
  heroImage: "",
  showNote: true,
  noteDesc: "Nloadno",
  transactionTitle: "",
  redeemUrl: "",
  redeemCTAText: "",
  showRedeemCTA: false,
  transactionNullStateImage: "",
  transactionNullStateText: "",
  haveCustomTransactionType: true,
}

export const paymentTypes = Object.freeze({
  TOTAL_DUES: "total due",
  MINIMUM_DUES: "minimum due",
  CUSTOM: "custom amount",
})

export const offerType = Object.freeze({
  PROGRAM_OFFERS: "PROGRAM_OFFERS",
  CAMPAIGN_OFFERS: "CAMPAIGN_OFFERS",
  WELCOME_OFFERS: "WELCOME_OFFER",
  THIRD_PARTY_OFFERS: "THIRD_PARTY_OFFERS",
  MERCHANT_OFFERS: "MERCHANT_OFFERS",
  BANK_OFFERS: "BANK_OFFERS",
  CROSS_SELL_OFFERS: "CROSS_SELL_OFFERS",
  INSTANT_DISCOUNT: "INSTANT_DISCOUNT",
})

export const benefitType = Object.freeze({
  TRANSACTIONAL: "TRANSACTIONAL",
  MILESTONE: "MILESTONE",
  MERCHANT_OFFERS: "MERCHANT_OFFERS",
  WELCOME_OFFERS: "WELCOME_OFFER",
})

export const CardActions = Object.freeze({
  CARD_SETTING: "cardSettings",
  FREEZE_CARD: "freezeCard",
  MORE_ACTIONS: "moreActions",
  NEW_CARD: "newCard",
  UNFREEZE_CARD: "unfreezeCard",
  HELP_AND_SUPPORT: "helpAndSupport",
})

export const CardAnimationNames = Object.freeze({
  CARD_FLASH: "CARD_FLASH",
  TILT_SHAKING: "TILT_SHAKING",
  GROW: "GROW",
  VIEW_CARD: "VIEW_CARD",
  BUBBLE: "BUBBLE",
})

export const RewardsRecordType = Object.freeze({
  POSTED: "POSTED",
  PENDING: "PENDING",
  REDEEMED: "REDEEMED",
  PARTIALLY_REDEEMED: "PARTIALLY_REDEEMED",
  EXPIRED: "EXPIRED",
  EXPIRING_SOON: "EXPIRING_SOON",
  REFUNDED: "REFUNDED",
  CANCELLED: "CANCELLED",
  VERIFIED_FOR_POSTING: "VERIFIED_FOR_POSTING ",
  REVERSED: "REVERSED",
  EARNED: "EARNED",
})

export const FilterType = Object.freeze({
  DATE: "DATE",
  SORT_BY: "SORT_BY",
  CATEGORIES: "CATEGORIES",
  CREDITED: "CREDITED",
  REDEEMED: "REDEEMED",
  REVERSED: "REVERSED",
  PENDING: "PENDING",
  POSTED: "POSTED",
  EARNED: "EARNED",
  EXPIRED: "EXPIRED",
})

export const FilterBottomSheetTabs = Object.freeze({
  CYCLE: "CYCLE",
  DURATION: "DURATION",
})

export const RewardsRedirectionType = Object.freeze({
  LR_TRANSACTIONS: "LR_TRANSACTIONS",
  HF_REWARDS: "HF_REWARDS",
  SSO: "SSO",
  URL: "URL",
  HF_CASHBACK: "HF_CASHBACK",
})

export const NudgeContentType = Object.freeze({
  ARROW: "ARROW",
  TITLE: "TITLE",
  SUBTITLE: "SUBTITLE",
  BACKGROUND: "BACKGROUND",
  BORDER: "BORDER",
})

export const RewardsBottomSheetVariant = Object.freeze({
  LIFETIME_POINTS: "LIFETIME_POINTS",
  EXPIRING_SOON: "EXPIRING_SOON",
})

export const MoneyMovementIndicator = Object.freeze({
  CREDIT: "cr",
  DEBIT: "dr",
})

export const ErrorType = Object.freeze({
  INTERNAL_ERROR: "INTERNAL_ERROR",
  NO_INTERNET_ERROR: "NO_INTERNET_ERROR",
})

export const GenerateLrSsoUrlStatus = Object.freeze({
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
})

export const SsoType = Object.freeze({
  REQUIRED: "REQUIRED",
  OPTIONAL: "OPTIONAL",
  SKIP: "SKIP",
})

export const SsoCategory = Object.freeze({
  REWARDS_REDEMPTION: "REWARDS_REDEMPTION",
  REPAYMENT: "REPAYMENT",
})

export const transactionFilterLables = Object.freeze({
  CYCLE: "CYCLE",
  SPENDS: "SPENDS",
  REFUNDS: "REFUNDS",
  CASHBACK: "CASHBACK",
})

export const transactionFilterTypes = Object.freeze({
  PURCHASE: "PURCHASE",
  REFUND: "REFUND",
  CASHBACK: "CASHBACK",
})
