import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Header } from "../../core"
import CampaignOfferCard from "./Components/campaignOfferCard"
import { useNavigate } from "react-router-dom"
import MerchantOffersCarousel from "./Components/merchantOfferCarousel"

const AllCampaignOffers = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useSelector(state => state.theme)
  const benefits = useSelector(state => state.benefits)

  const campaignOffers = benefits?.campaignBenefits.sort(
    (a, b) => a?.displayOrder - b?.displayOrder,
  )
  const merchantOffers = benefits?.merchantBenefits

  return (
    <div
      style={{
        minHeight: window.innerHeight,
        backgroundColor: theme?.v3.cssVars.leadingWhite,
      }}
    >
      <Header
        text={t("offers.allMilestonesOfferTitle")}
        onBack={async () => {
          window.history.go(-1)
        }}
      />
      <div className='v3-AllCampaignOffers-offersContainer'>
        {campaignOffers?.map((offer, index) => {
          return <CampaignOfferCard key={index} offer={offer} />
        })}
      </div>
      {merchantOffers && merchantOffers.length !== 0 && (
        <div className='v3-AllCampaignOffers-merchantOffersContainer'>
          <div className='v3-AllCampaignOffers-merchantOffersContainer-header'>
            <div style={{ color: theme?.v3.cssVars.primaryBase.color1 }}>
              {t("offers.dealsOnBrandsText")}
            </div>
            <div
              onClick={() => navigate("/MerchantOffers")}
              style={{ color: theme?.v3.cssVars.secondary.color1 }}
            >
              {t("offers.ViewAll")}
            </div>
          </div>
          <MerchantOffersCarousel />
        </div>
      )}
    </div>
  )
}

export default AllCampaignOffers
