import React, { useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { ColorButton } from "../../core/buttons"
import {
  formatToWholeCurrency,
  lrSsoRedirection,
} from "../../../utils/functions"
import {
  RewardsRedirectionType,
  SsoCategory,
  SsoType,
} from "../../../utils/constants"
import ArrowRightSmall from "../../svg/v3/arrowRightSmall"
import RewardBackground from "../../../assets/images/v3/rewardPointsBannerBackground.svg"
import CashbackBackground from "../../../assets/images/v3/cashbackBannerBackground.svg"
import Hourglass_1 from "../../svg/v3/hourglass_1"
import ArrowRightLarge from "../../svg/v3/arrowRightLarge"
import EarnFirstPointBanner from "../../../assets/images/v3/earnFirstPointBanner.svg"
import Cashback from "../../svg/v3/Cashback"
import Rewards from "../../svg/v3/Rewards"

const RewardsCashbackBanner = ({
  isRewards,
  isCashback,
  onLifetimePointsClick,
  onExpiringSoonClick,
}) => {
  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const screen = useSelector(state => state.screen)
  const config = useSelector(state => state.config)

  const [redirectionLoading, setRedirectionLoading] = useState(false)

  const navigate = useNavigate()

  const rewardRedirectionType = screen?.home?.reward?.redirectionTypesV3

  const cashbackData = user?.hfCashback

  const {
    primary: {
      color1: primaryColor1,
      color2: primaryColor2,
      color3: primaryColor3,
      color4: primaryColor4,
    },
    tertiaryNeutral: { color1: tertiaryNeutralColor1 },
    tertiaryNegative: { color1: tertiaryNegativeColor1 },
    leadingWhite,
  } = theme?.v3?.rawColors

  const rewardsData = rewardRedirectionType?.includes(
    RewardsRedirectionType.LR_TRANSACTIONS,
  )
    ? {
        available: Number(user?.rewardSummary?.currentValue),
        lifetimeEarned:
          Number(user?.rewardSummary?.metadata?.balance) +
          Number(user?.rewardSummary?.metadata?.redeemed),
        redeemed: Number(user?.rewardSummary?.metadata?.redeemed),
        expired: Number(user?.rewardSummary?.metadata?.lapsed),
      }
    : user?.aggregate

  const onRedeemNowClick = () => {
    if (
      rewardRedirectionType?.includes(RewardsRedirectionType.LR_TRANSACTIONS)
    ) {
      lrSsoRedirection(
        {
          accountId: user.account.id,
          category: SsoCategory.REWARDS_REDEMPTION,
          sso: SsoType.REQUIRED,
        },
        config?.jsonConfig?.rewards?.redeemUrl,
        setRedirectionLoading,
      )
    } else {
      if (config?.jsonConfig?.rewards?.redeemUrl) {
        window.open(config?.jsonConfig?.rewards?.redeemUrl)
      }
    }
  }

  return (
    <div className='v3-rewards-cashback-points-banner-wrapper'>
      <div
        style={{
          backgroundColor: isRewards
            ? "var(--primary-color-1)"
            : "var(--primary-color-2)",
        }}
      >
        <img
          src={isRewards ? RewardBackground : CashbackBackground}
          alt='background-pattern'
        />
      </div>
      {isRewards ? (
        <Rewards
          customClassname='v3-rewards-cashback-points-banner-icon'
          backgroundColor={leadingWhite}
          circleColor={primaryColor1}
          powerColor={leadingWhite}
        />
      ) : (
        <Cashback
          customClassname='v3-rewards-cashback-points-banner-cashback-icon'
          circleColor={primaryColor1}
          backgroundColor={primaryColor3}
          borderColor={primaryColor2}
          shadowColor={tertiaryNeutralColor1}
          rupeeColor={leadingWhite}
        />
      )}

      <div className='v3-rewards-cashback-points-banner-points-container'>
        <div>
          {isRewards
            ? Number(rewardsData?.available || 0)
            : formatToWholeCurrency(cashbackData?.earned || 0)}
        </div>
        <div>{isRewards ? "Points Available" : "Cashback Earned"}</div>
      </div>

      {isRewards && rewardsData?.lifetimeEarned ? (
        <div
          className='v3-rewards-cashback-points-lifetime-points-container'
          onClick={() => onLifetimePointsClick()}
        >
          <div className='v3-rewards-cashback-points-lifetime-points-left'>
            <div className='v3-rewards-cashback-points-lifetime-points-text'>
              Lifetime points earned
            </div>
            <Rewards
              customClassname='v3-rewards-cashback-points-lifetime-points-icon'
              backgroundColor={primaryColor4}
              circleColor={primaryColor1}
              powerColor={leadingWhite}
            />
            <div className='v3-rewards-cashback-points-lifetime-points'>
              {Number(rewardsData?.lifetimeEarned || 0)}
            </div>
          </div>
          <ArrowRightSmall color={primaryColor1} />
        </div>
      ) : (
        <></>
      )}

      {isRewards && !rewardsData?.lifetimeEarned && !rewardsData?.pending ? (
        <div
          className='v3-rewards-cashback-points-earn-banner'
          onClick={() => {
            navigate("/CvpOffers")
          }}
        >
          <img src={EarnFirstPointBanner} alt='first-point' />
          <div className='v3-rewards-cashback-points-earn-banner-text'>
            <div>Earn your first reward points now!</div>
            <div>
              <div>See Offers</div> <ArrowRightSmall />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {isRewards && rewardsData?.available ? (
        <div className='v3-rewards-cashback-points-bottom-section'>
          {rewardsData?.expiring > 0 && (
            <div
              className='v3-rewards-cashback-points-expiring'
              onClick={() => onExpiringSoonClick()}
            >
              <Hourglass_1 color={tertiaryNegativeColor1} />
              <div>{rewardsData?.expiring} Pts expiring soon</div>
              <ArrowRightLarge color={tertiaryNegativeColor1} />
            </div>
          )}
          <ColorButton
            width={"100%"}
            text={"Redeem Now"}
            onPress={onRedeemNowClick}
            isLoading={redirectionLoading}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default RewardsCashbackBanner
