import { getApiConfig } from "../utils/functions"
import Service from "./Service"

const CardService = {
  getCardDetails: (apiToken, cardId) =>
    Service.get(`/cards/${cardId}`, getApiConfig(apiToken)),

  getCardControls: (apiToken, cardId) =>
    Service.get(`/cards/${cardId}/get-card-controls`, getApiConfig(apiToken)),

  unMaskCard: (apiToken, cardId) =>
    Service.get(`/cards/${cardId}/unmask-card`, getApiConfig(apiToken)),

  lockCard: (apiToken, cardId) =>
    Service.put(`/cards/${cardId}/lock-card`, null, getApiConfig(apiToken)),

  unlockCard: (apiToken, cardId) =>
    Service.put(`/cards/${cardId}/unlock-card`, null, getApiConfig(apiToken)),

  updateCardControls: (apiToken, cardId, data) =>
    Service.put(
      `/cards/${cardId}/update-card-controls`,
      data,
      getApiConfig(apiToken),
    ),

  activateCard: (apiToken, cardId, data) =>
    Service.put(`/cards/${cardId}/activate`, data, getApiConfig(apiToken)),

  replace: (apiToken, cardId, data) =>
    Service.post(`/cards/${cardId}/replace`, data, getApiConfig(apiToken)),

  hotlist: (apiToken, cardId, data) =>
    Service.put(`/cards/${cardId}/hotlist`, data, getApiConfig(apiToken)),

  resetPin: (apiToken, cardId, data) =>
    Service.put(`/cards/${cardId}/reset-pin`, data, getApiConfig(apiToken)),

  verifyActivationCode: (apiToken, cardId, activationCode) =>
    Service.get(`/cards/${cardId}/verify-activation-code`, {
      params: { activationCode },
      ...getApiConfig(apiToken),
    }),

  getCardDetailsWidget: (apiToken, cardId, data) =>
    Service.post(
      `/cards/${cardId}/get-card-details-widget`,
      data,
      getApiConfig(apiToken),
    ),

  getResetPinWidget: (apiToken, cardId, data) =>
    Service.post(
      `/cards/${cardId}/get-reset-pin-widget`,
      data,
      getApiConfig(apiToken),
    ),
}

export default CardService
