import {
  SET_CLIENT_PARAMS,
  SET_CSS_LOADED,
  SET_CURRENT_OFFER,
  SET_DISABLED_FUNCTIONS_BLOCK_CODE,
  SET_EVENT_FLOW,
  SET_FIRST_TIME_LOADED,
  SET_ISSUER,
  SET_NUDGES,
  SET_ONBOARDING_URL,
  SET_REDIRECT_URL,
  SET_REPAYMENT_DETAILS,
  SET_SELECTED_EMI,
  SET_SESSION,
  SET_SESSION_EXPIRY,
  SET_SESSION_INACTIVE_EXPIRY,
} from "../ActionTypes"

const initialState = {
  sessionToken: null,
  deviceToken: null,
  encryptionKey: null,
  encryptionIv: null,
  redirectUrl: "/",
  onboardingUrl: "/",
  sessionExpired: false,
  repaymentFlow: false,
  repaymentUrl: "",
  currentSelectedEmi: null,
  disabledFunctionsByBlockCode: null,
  isLoadedFirstTime: true,
  isCssLoaded: false,
  pwaSessionInActiveTimeoutMinutes: 100,
  currentOffer: null,
  eventFlow: null,
}

const SessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SESSION:
      return {
        ...state,
        sessionToken: action.payload.sessionToken,
        deviceToken: action.payload.deviceToken,
        encryptionKey: action.payload.encryptionKey,
        encryptionIv: action.payload.encryptionIv,
      }
    case SET_SESSION_INACTIVE_EXPIRY:
      return {
        ...state,
        pwaSessionInActiveTimeoutMinutes: Number(
          action.payload.pwaSessionInActiveTimeoutMinutes,
        ),
      }
    case SET_REDIRECT_URL:
      return {
        ...state,
        redirectUrl: action.payload.redirectUrl,
      }
    case SET_CURRENT_OFFER:
      return {
        ...state,
        currentOffer: action.payload.currentOffer,
      }
    case SET_ONBOARDING_URL:
      return {
        ...state,
        onboardingUrl: action.payload.onboardingUrl,
      }
    case SET_SELECTED_EMI:
      return {
        ...state,
        currentSelectedEmi: action.payload.currentSelectedEmi,
      }
    case SET_REPAYMENT_DETAILS:
      return {
        ...state,
        repaymentFlow: action.payload.repaymentFlow,
        repaymentUrl: action.payload.repaymentUrl,
      }
    case SET_SESSION_EXPIRY:
      return {
        ...state,
        sessionExpired: action.payload.sessionExpired,
      }
    case SET_DISABLED_FUNCTIONS_BLOCK_CODE:
      return {
        ...state,
        disabledFunctionsByBlockCode:
          action.payload.disabledFunctionsByBlockCode,
      }
    case SET_FIRST_TIME_LOADED:
      return {
        ...state,
        isLoadedFirstTime: action.payload.isLoadedFirstTime,
      }
    case SET_ISSUER:
      return {
        ...state,
        issuer: action.payload.issuer,
      }
    case SET_EVENT_FLOW:
      return {
        ...state,
        eventFlow: action.payload.eventFlow,
      }
    case SET_CLIENT_PARAMS:
      return {
        ...state,
        clientParams: action.payload.clientParams,
      }
    case SET_CSS_LOADED:
      return {
        ...state,
        isCssLoaded: action.payload.isCssLoaded,
      }
    case SET_NUDGES:
      return {
        ...state,
        nudges: action.payload.nudges,
      }
    default:
      return state
  }
}

export default SessionReducer
