import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Header } from "../../core"
import { ColorButton } from "../../core/buttons"
import backgroundPattern from "../../../assets/images/v3/dashedPattern.svg"
import CompletedMilestoneOfferCard from "./Components/completedMilestoneOfferCard"
import Divider from "../../core/Divider/v3/Divider"
import OfferDetailsAccordian from "./Components/offerDetailsAccordian"
import PendingMilestoneCard from "./Components/pendingMilestoneCard"
import arrowBullet from "../../../assets/images/v3/arrowBullet.svg"
import { getDifferenceInDays, hexToRGBA } from "../../../utils/functions"
import Hourglass from "../../svg/v3/hourGlassIcon"
import defaultLogo from "../../../assets/images/v3/Vector.svg"

const CampaignOfferDetails = () => {
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  const benefits = useSelector(state => state.benefits)
  const [offerExpiry, setOfferExpiry] = useState(0)
  const params = useParams()

  const { BenefitId } = params
  const offer = benefits?.campaignBenefits?.filter(offer => {
    return offer?.benefitId === BenefitId
  })?.[0]
  let offerDetails
  if (offer) {
    offerDetails = JSON.parse(offer?.offerDetails)
  }

  useEffect(() => {
    setOfferExpiry(getDifferenceInDays(offer?.activeTo))
  }, [offer])

  const handleCtaClick = () => {
    if (offerDetails?.ctaLink) {
      window.location.href = offerDetails?.ctaLink
    }
  }
  return (
    <>
      <div
        style={{
          height: window.innerHeight,
          backgroundColor: theme?.v3.cssVars.leadingWhite,
          overflowY: "auto",
        }}
        className='v3-cvpOfferDetails'
      >
        <Header
          text={`${t("offers.campaignOfferDetailsHeaderPrefix")} - ${offer?.displayTitle}`}
          onBack={async () => {
            window.history.go(-1)
          }}
        />
        <div
          className='v3-campaignOfferDetails-banner-container'
          style={{
            background: offer?.displayColor
              ? offer?.displayColor
              : theme.v3.cssVars.primary.color1,
          }}
        >
          {offer?.displayColor && (
            <img
              src={backgroundPattern}
              alt='dashed-pattern'
              className='v3-campaignOfferDetails-backgroundPattern'
            />
          )}
          <div className='v3-campaignOfferDetails-offerBanner'>
            {offer?.logoUrl ? (
              <img
                src={offer?.logoUrl}
                alt='brand-Logo'
                className='v3-campaignOfferDetails-Logo'
              />
            ) : (
              <img src={defaultLogo} className='v3-campaignOfferDetails-Logo' />
            )}
            <div
              className='v3-campaignOfferDetails-offerBanner-text'
              style={{ color: theme.v3.cssVars.leadingWhite }}
            >
              <div>{offer?.displayDescription || offer?.description}</div>
              <span>
                <Hourglass color={theme?.v3.rawColors.leadingWhite} />
                {t("offers.expiresText")}{" "}
                {offerExpiry <= 1
                  ? "Today"
                  : `in ${offerExpiry.toFixed(0)} days`}
              </span>
            </div>
          </div>
        </div>
        <div className='v3-campaignOfferDetails-details'>
          <div className='v3-campaignOfferDetails-progressContainer'>
            {offer?.threshold !== 0 ? (
              <>
                {offer?.currentValue >= offer?.threshold ? (
                  <>
                    <CompletedMilestoneOfferCard
                      details={offerDetails}
                      isCampaignOffer
                    />
                    {offerDetails?.completedText3 && (
                      <div
                        className='v3-cvpOfferDetails-completedOffer-infoCard'
                        style={{
                          background: theme?.v3.cssVars.leadingWhite,
                        }}
                      >
                        <div
                          style={{
                            background:
                              theme?.v3.cssVars.tertiaryNeutral.color3,
                          }}
                        >
                          <img src={arrowBullet} alt='bullet' />
                          <div>{offerDetails?.completedText3}</div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <PendingMilestoneCard details={offerDetails} offer={offer} />
                )}
              </>
            ) : (
              <div
                className='v3-campaignOfferDetails-nonMilestoneOffer-infoCard'
                style={{
                  background: theme.v3.cssVars.leadingWhite,
                  borderColor: theme.v3.cssVars.primaryBase.color2,
                }}
              >
                <img src={offer?.illustrationUrl} />
                <div>{offerDetails?.subText}</div>
              </div>
            )}
          </div>
          {offerDetails?.content && (
            <div
              className='v3-cvpOfferDetails-text-accordian-container'
              style={{ background: theme?.widgetBackgroundColor }}
            >
              {offerDetails?.content?.map((element, index) => {
                return (
                  <>
                    {index !== 0 && (
                      <Divider
                        height='1px'
                        color={theme.v3.rawColors.tertiaryNeutral.color3}
                      />
                    )}
                    <OfferDetailsAccordian
                      header={element?.title}
                      points={element?.points}
                      open={index === 0}
                    />
                  </>
                )
              })}
            </div>
          )}
        </div>
        <div
          className='v3-cvpOfferDetails-cta'
          style={{ background: theme?.v3.cssVars.leadingWhite }}
        >
          <ColorButton
            text={offerDetails?.ctaText}
            width={"100%"}
            onPress={handleCtaClick}
            isDisable={
              offer?.threshold !== 0 && offer?.currentValue < offer?.threshold
            }
          />
        </div>
      </div>
    </>
  )
}

export default CampaignOfferDetails
