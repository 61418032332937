import React from "react"
import { useTranslation } from "react-i18next"
import moment from "moment"
import LineBreaker from "../../../core/lineBreaker"
import {
  colorPicker,
  captureEvents,
  formatAmount,
} from "../../../../utils/functions"
import { useSelector } from "react-redux"
import usePayButtonText from "../../../../hooks/usePayButtonText"
import {
  AU_BlockCodes,
  EventName,
  Federal_BlockCodes,
  RepaymentTypes,
  cardSections,
} from "../../../../utils/enums"
import { ProgramTypes } from "../../../../utils/constants"

const AccountSummary = ({ props, setIsPaymentGatewayOpen, user }) => {
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  const session = useSelector(state => state.session)
  const featureFlags = useSelector(state => state.featureFlags)
  const screen = useSelector(state => state.screen)
  const textColor = theme.appTextColor
  const ctaPrimaryColor = theme.color1
  const ctaSecondaryColor = theme.color2
  const widgetBackgroundColor = theme.widgetBackgroundColor

  const textBase1 = colorPicker(textColor).primary1
  const textBase2 = colorPicker(textColor).primary2

  const ctaPrimaryColor1 = colorPicker(ctaPrimaryColor).primary1
  const ctaPrimaryColor2 = colorPicker(ctaPrimaryColor).primary2

  const getPayButtonText = usePayButtonText()

  const isRepaymentFlowEnabled = session.repaymentFlow

  const repayUrlCall = () => {
    if (screen.home.repayments.repaymentType !== RepaymentTypes.S2S_PG) {
      window.location.href = session.repaymentUrl
    } else setIsPaymentGatewayOpen(true)
  }
  const renderPayBillWidget = () => {
    if (Object.keys(props.showReminder).length === 0) {
      return (
        <div className='v3-activity-section-balance-widget-wrapper'>
          <div className='v3-activity-section-balance'>
            <div className='v3-activity-section-balance-amount-section'>
              <div className='v3-activity-section-balance-amount-section-value'>
                {t("AccountSummary.noDues")}
              </div>
              {Number(props.creditDetails.totalCredit) -
                Number(props.creditDetails.availableCredit) <=
              0 ? (
                <div className='v3-activity-section-balance-amount-section-due'>
                  You’re all clear!
                </div>
              ) : (
                <div
                  className='v3-activity-section-balance-amount-section-due'
                  style={{ textAlign: "left" }}
                >
                  Some outstanding amount is yet to be paid 💰
                </div>
              )}
            </div>
            {isRepaymentFlowEnabled && getPayButtonText && (
              <div
                className='v3-activity-section-balance-amount-cta'
                onClick={() => {
                  session.disabledFunctionsByBlockCode?.includes(
                    Federal_BlockCodes.PAY_BILL_DISABLE,
                  ) ||
                  session?.disabledFunctionsByBlockCode?.includes(
                    AU_BlockCodes.PAY_BILL_DISABLE,
                  ) ? (
                    <></>
                  ) : (
                    repayUrlCall()
                  )
                  captureEvents({
                    eventName: EventName.PAY_BILL,
                    metadata: { source: EventName.HOME },
                  })
                }}
                style={{
                  backgroundColor:
                    Number(props.creditDetails.totalCredit) -
                      Number(props.creditDetails.availableCredit) >
                      0 && theme.v3.cssVars.primaryBase.color1,
                }}
              >
                {/* <ColorButton
    text={"Pay Bill"}
    // isDisable={!enableVerify}
    onPress={repayUrlCall}
    // isLoading={submitLoading}
    borderRadius={"12px"}
  /> */}
                {getPayButtonText}
              </div>
            )}
          </div>
        </div>
      )
    } else {
      if (props.showReminder.amount <= 0) {
        // fully paid state
        return (
          <div className='v3-activity-section-balance-widget-wrapper'>
            <div className='v3-activity-section-balance'>
              <div className='v3-activity-section-balance-amount-section'>
                <div
                  className='v3-activity-section-balance-amount-section-value v3-activity-balance-amount-section-fully-paid-value'
                  style={{ color: theme.v3.cssVars.tertiaryPositive.color1 }}
                >
                  {t("AccountSummary.fullyPaid")}
                </div>
                {Number(props.creditDetails.totalCredit) -
                  Number(props.creditDetails.availableCredit) <=
                0 ? (
                  <div className='v3-activity-section-balance-amount-section-due'>
                    {t("AccountSummary.noBillsDue")}
                  </div>
                ) : (
                  <div
                    className='v3-activity-section-balance-amount-section-due'
                    style={{ textAlign: "left" }}
                  >
                    {t("AccountSummary.outstandingAmtToBePaid")}
                  </div>
                )}
              </div>
              {isRepaymentFlowEnabled && getPayButtonText && (
                <div
                  className='v3-activity-section-balance-amount-cta'
                  onClick={() => {
                    session.disabledFunctionsByBlockCode?.includes(
                      Federal_BlockCodes.PAY_BILL_DISABLE,
                    ) ||
                    session?.disabledFunctionsByBlockCode?.includes(
                      AU_BlockCodes.PAY_BILL_DISABLE,
                    ) ? (
                      <></>
                    ) : (
                      repayUrlCall()
                    )
                    captureEvents({
                      eventName: EventName.PAY_BILL,
                      metadata: { source: EventName.HOME },
                    })
                  }}
                  style={{
                    backgroundColor:
                      Number(props.creditDetails.totalCredit) -
                        Number(props.creditDetails.availableCredit) >=
                        0 && theme.v3.cssVars.primaryBase.color1,
                  }}
                >
                  {/* <ColorButton
                    text={"Pay Bill"}
                    // isDisable={!enableVerify}
                    onPress={repayUrlCall}
                    // isLoading={submitLoading}
                    borderRadius={"12px"}
                  /> */}
                  {getPayButtonText}
                </div>
              )}
            </div>
          </div>
        )
      } else {
        if (moment() > moment(props.showReminder.dueDate)) {
          //overdue state
          return (
            <>
              <div className='v3-activity-section-balance-widget-wrapper'>
                <div className='v3-activity-section-balance'>
                  <div className='v3-activity-section-balance-amount-section'>
                    <div
                      className='v3-activity-section-balance-amount-section-value'
                      style={{ color: textBase1 }}
                    >
                      {formatAmount(props.showReminder.amount)}
                    </div>
                    <div className='v3-activity-section-balance-amount-section-overdue'>
                      {t("AccountSummary.billOverdueOn", {
                        date: moment(props.showReminder.dueDate).format(
                          "DD MMM 'YY",
                        ),
                      })}
                    </div>
                  </div>
                  {isRepaymentFlowEnabled && getPayButtonText && (
                    <div
                      className='v3-activity-section-balance-amount-cta'
                      style={{
                        background: theme.v3.cssVars.tertiaryNegative.color1,
                      }}
                      onClick={() => {
                        session.disabledFunctionsByBlockCode?.includes(
                          Federal_BlockCodes.PAY_BILL_DISABLE,
                        ) ||
                        session?.disabledFunctionsByBlockCode?.includes(
                          AU_BlockCodes.PAY_BILL_DISABLE,
                        ) ? (
                          <></>
                        ) : (
                          repayUrlCall()
                        )
                        captureEvents({
                          eventName: EventName.PAY_BILL,
                          metadata: { source: EventName.HOME },
                        })
                      }}
                    >
                      {/* <ColorButton
                text={"Pay Bill"}
                // isDisable={!enableVerify}
                onPress={repayUrlCall}
                // isLoading={submitLoading}
                borderRadius={"12px"}
              /> */}
                      {getPayButtonText}
                    </div>
                  )}
                </div>
              </div>
            </>
          )
        } else {
          if (props.showReminder.amount !== props.showReminder.totalAmountDue) {
            //partially paid
            return (
              <div
                className='v3-activity-section-balance-widget-wrapper'
                style={{ background: widgetBackgroundColor }}
              >
                <div className='v3-activity-section-balance'>
                  <div className='v3-activity-section-balance-amount-section'>
                    <div
                      className='v3-activity-section-balance-amount-section-value'
                      style={{ color: textBase1 }}
                    >
                      {formatAmount(props.showReminder.amount)}
                    </div>
                    <div className='v3-activity-section-balance-amount-section-due'>
                      {t("AccountSummary.remainingDueOn", {
                        date: moment(props.showReminder.dueDate).format(
                          "DD MMM 'YY",
                        ),
                      })}
                    </div>
                  </div>
                  {isRepaymentFlowEnabled && getPayButtonText && (
                    <div
                      className='v3-activity-section-balance-amount-cta'
                      onClick={() => {
                        session.disabledFunctionsByBlockCode?.includes(
                          Federal_BlockCodes.PAY_BILL_DISABLE,
                        ) ||
                        session?.disabledFunctionsByBlockCode?.includes(
                          AU_BlockCodes.PAY_BILL_DISABLE,
                        ) ? (
                          <></>
                        ) : (
                          repayUrlCall()
                        )
                        captureEvents({
                          eventName: EventName.PAY_BILL,
                          metadata: { source: EventName.HOME },
                        })
                      }}
                    >
                      {/* <ColorButton
                    text={"Pay Bill"}
                    // isDisable={!enableVerify}
                    onPress={repayUrlCall}
                    // isLoading={submitLoading}
                    borderRadius={"12px"}
                  /> */}
                      {getPayButtonText}
                    </div>
                  )}
                </div>
              </div>
            )
          } else {
            // general state (not partially paid)
            return (
              <div className='v3-activity-section-balance-widget-wrapper'>
                <div className='v3-activity-section-balance'>
                  <div className='v3-activity-section-balance-amount-section'>
                    <div
                      className='v3-activity-section-balance-amount-section-value'
                      style={{ color: textBase1 }}
                    >
                      {formatAmount(props.showReminder.amount)}
                    </div>
                    <div className='v3-activity-section-balance-amount-section-due'>
                      Bill Due on{" "}
                      {moment(props.showReminder.dueDate).format("DD MMM 'YY")}
                    </div>
                  </div>
                  {isRepaymentFlowEnabled && getPayButtonText && (
                    <div
                      className='v3-activity-section-balance-amount-cta'
                      onClick={() => {
                        session.disabledFunctionsByBlockCode?.includes(
                          Federal_BlockCodes.PAY_BILL_DISABLE,
                        ) ||
                        session?.disabledFunctionsByBlockCode?.includes(
                          AU_BlockCodes.PAY_BILL_DISABLE,
                        ) ? (
                          <></>
                        ) : (
                          repayUrlCall()
                        )
                        captureEvents({
                          eventName: EventName.PAY_BILL,
                          metadata: { source: EventName.HOME },
                        })
                      }}
                    >
                      {/* <ColorButton
                text={"Pay Bill"}
                // isDisable={!enableVerify}
                onPress={repayUrlCall}
                // isLoading={submitLoading}
                borderRadius={"12px"}
              /> */}
                      {getPayButtonText}
                    </div>
                  )}
                </div>
              </div>
            )
          }
        }
      }
    }
  }

  return (
    <div
      style={{
        background: theme.v3.cssVars.primary.color5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "20px",
        padding: "28px 0",
      }}
    >
      <div className='v3-activity-section-summary'>
        <div className='v3-activity-section-title'>
          {t("AccountSummary.accountSummary")}
        </div>
        <div className='v3-activity-section-summary-value-section'>
          <div className='v3-activity-section-summary-value-section-title'>
            {t("AccountSummary.availableCredit")}
          </div>
          <div className='v3-activity-section-summary-value-section-value'>
            {formatAmount(props.creditDetails.availableCredit)}/
            <span className='v3-activity-section-summary-value-section-value-sub'>
              {formatAmount(props.creditDetails.totalCredit)}
            </span>
          </div>
        </div>
        <div className='v3-activity-section-summary-value-section'>
          <div className='v3-activity-section-summary-value-section-title'>
            {user.programType === ProgramTypes.CREDIT_CARD
              ? "Total Outstanding"
              : "Used Credit"}
          </div>
          {Number(props.creditDetails.totalCredit) -
            Number(props.creditDetails.availableCredit) >=
          0 ? (
            <div className='v3-activity-section-summary-value-section-value'>
              {formatAmount(
                Number(props.creditDetails.totalCredit) -
                  Number(props.creditDetails.availableCredit),
              )}
            </div>
          ) : (
            <div
              className='v3-activity-section-summary-value-section-value'
              style={{ color: theme.v3.cssVars.tertiaryPositive.color1 }}
            >
              {formatAmount(
                Number(props.creditDetails.totalCredit) -
                  Number(props.creditDetails.availableCredit),
                true,
              )}
            </div>
          )}
        </div>
      </div>
      {/* <LineBreaker /> */}
      {renderPayBillWidget()}
    </div>
  )
}

export default AccountSummary
